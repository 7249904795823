import { liveImport } from 'services/AdminService';
import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Button, TextField, Chip, Box } from '@mui/material';
import Loading from 'lib/Loading';
import { Add } from '@mui/icons-material';

export default function ManualImport() {
    const [updatePlayers, setUpdatePlayers] = useState(true);
    const [checkExisting, setCheckExisting] = useState(true);
    const [dryRun, setDryRun] = useState(false);
    const [paths, setPaths] = useState([]);
    const [pathInput, setPathInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const postLiveIngest = () => {
        setIsLoading(true);
        liveImport(
            {
                updatePlayers,
                checkExisting,
                dryRun,
                paths
            },
            (res) => {
                setIsLoading(false);
                console.log(res);
            }
        );
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={updatePlayers}
                            onChange={(e) => setUpdatePlayers(e.target.checked)}
                        />
                    }
                    label="Update Players"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkExisting}
                            onChange={(e) => setCheckExisting(e.target.checked)}
                        />
                    }
                    label="Check Existing"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={dryRun} onChange={(e) => setDryRun(e.target.checked)} />
                    }
                    label="Dry Run"
                />
            </Box>

            <Box sx={{ maxWidth: '700px', display: 'flex' }}>
                <TextField
                    label="Path"
                    variant="outlined"
                    sx={{ width: '90%', mr: 2 }}
                    value={pathInput}
                    onChange={(e) => setPathInput(e.target.value)}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setPaths([...paths, pathInput]);
                        setPathInput('');
                    }}>
                    <Add />
                </Button>
            </Box>
            <div>
                {paths.map((path, index) => (
                    <Chip
                        key={index}
                        label={path}
                        onDelete={() => {
                            setPaths(paths.filter((_, i) => i !== index));
                        }}
                    />
                ))}
            </div>
            <Button
                sx={{ maxWidth: '200px' }}
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={postLiveIngest}
                endIcon={isLoading && <Loading />}>
                Submit
            </Button>
        </Box>
    );
}
