import { APP_CONFIG } from 'config/config';

const baseUrl = `${APP_CONFIG.apiUrl}/v1/collisions`;

export function createCollision(request, callback) {
    fetch(`${baseUrl}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}

export function listCollisions(request, callback) {
    fetch(`${baseUrl}/`)
        .then((response) => response.json())
        .then((response) => callback(response));
}

export function loadCollision(id, callback) {
    fetch(`${baseUrl}/${id}`)
        .then((response) => response.json())
        .then((response) => callback(response));
}

export function executeCollision(id, callback) {
    fetch(`${baseUrl}/${id}/execute`, {
        method: 'POST'
    }).then((response) => callback(response));
}

export function getCollisionJSONForExport(callback) {
    fetch(`${baseUrl}/export`)
        .then((response) => response.json())
        .then((response) => callback(response));
}

export function deleteCollision(id, callback) {
    fetch(`${baseUrl}/${id}`, {
        method: 'DELETE'
    }).then((response) => callback(response));
}

export function searchPlayersByName(name, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v2/teams/players-by-name?name=${name}`)
        .then((response) => response.json())
        .then((response) =>
            response.map((p) => {
                p.Rosters.forEach((r) => {
                    r.tournament.date = new Date(r.tournament.date);
                });
                return p;
            })
        )
        .then((response) => callback(response));
}
