import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    useTheme
} from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { poolGamesColumns } from './Pools';

export default function Clusters(props) {
    const [clusters, setClusters] = useState([]);
    const theme = useTheme();
    useEffect(() => {
        setClusters(props.stage.groups);
    });

    return (
        <Box sx={{ m: 1 }}>
            {clusters.map((group, index) => (
                <Accordion key={index} defaultExpanded={true} sx={{ maxWidth: '800px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>{group.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, overflow: 'auto' }}>
                        <DataTable
                            columns={poolGamesColumns(theme)}
                            rows={group.games}
                            useContainer={false}></DataTable>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
