import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getPageviewTimeseries, healthCheck } from 'services/AdminService';
import { timeAgo, formatDateNoYear, formatDatetime, formatDate } from 'utils/formatDatetime';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useTheme } from '@emotion/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function UsageBarChart({ usageData }) {
    if (!usageData) {
        return null;
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Monthly Sales'
            }
        }
    };

    return <Bar data={usageData} options={options} />;
}

export default function Stats() {
    const [counts, setCounts] = useState(undefined);
    const [hours, setHours] = useState(24);
    const [usageData, setUsageData] = useState(undefined);
    const theme = useTheme();
    useEffect(() => {
        healthCheck({}, (res) => {
            setCounts(res);
        });
    }, []);

    useEffect(() => {
        getPageviewTimeseries({ hours, n: 50 }, (res) => {
            setUsageData({
                labels: res.map((row) => {
                    const f = hours > 24 ? formatDate : formatDatetime;
                    return f(new Date(row.bucketStart));
                }),
                datasets: [
                    {
                        label: 'Pageviews',
                        data: res.map((row) => row.Count),
                        backgroundColor: theme.palette.primary.main
                    }
                ]
            });
        });
    }, [hours]);

    return (
        <div>
            <h3>Health</h3>
            <Table sx={{ width: '300px' }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>Table</b>
                        </TableCell>
                        <TableCell>
                            <b>Count</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {counts &&
                        Object.keys(counts).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{counts[key]}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <h3>Usage</h3>
            <Select value={hours} onChange={(e) => setHours(Number(e.target.value))}>
                <MenuItem value={24}>Days</MenuItem>
                <MenuItem value={168}>Weeks</MenuItem>
                <MenuItem value={720}>Months</MenuItem>
                <MenuItem value={8640}>Years</MenuItem>
            </Select>
            <UsageBarChart usageData={usageData} />
        </div>
    );
}
