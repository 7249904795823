import { Button, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { createBugReport } from 'services/BugReportService';

export default function BugReport() {
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const submit = () => {
        setSubmitting(true);
        createBugReport({ message: `${window.location.pathname}: ${message}` }, (response) => {
            if (response.status !== 201) {
                response.json().then((json) => {
                    setError(json.error);
                });
            } else {
                setSubmitted(true);
            }

            setSubmitting(false);
        });
    };

    return (
        <Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Bug report
            </Typography>
            <Box>
                <TextField
                    sx={{ width: '100%', maxWidth: '600px' }}
                    multiline
                    inputProps={{ maxLength: 512 }}
                    minRows={4}
                    label="Bug report"
                    placeholder="Describe the issue (max 512 characters)"
                    value={message}
                    onChange={(event) => {
                        setMessage(event.target.value);
                    }}
                />
            </Box>
            {!submitted && !submitting && (
                <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 2 }}
                    disabled={!message}
                    onClick={() => submit()}>
                    Submit
                </Button>
            )}
            <Box sx={{ mt: 2 }}>
                {submitting && <Typography sx={{ ml: 2 }}>Submitting...</Typography>}
                {submitted && <Typography sx={{ ml: 2 }}>Submitted!</Typography>}
                {error && <Typography sx={{ ml: 2 }}>Error: {error}</Typography>}
            </Box>
        </Box>
    );
}
