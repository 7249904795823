import { APP_CONFIG } from 'config/config';

const baseUrl = `${APP_CONFIG.apiUrl}/v1/videos`;

export function getVideosForTournament(tournamentId, callback) {
    return fetch(`${baseUrl}/tournaments/${tournamentId}`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getVideos(opts) {
    const { page, unmatched, sourceId, pageSize } = opts;
    const source = sourceId ? '&sourceId=' + sourceId : '';
    const pSize = pageSize ? '&pageSize=' + pageSize : '';

    return fetch(baseUrl + '?page=' + page + pSize + '&unmatched=' + unmatched + source);
}

export function getVideosForTeam(req, callback) {
    const pageSize = req.pageSize ? '?pageSize=' + req.pageSize : '';

    return fetch(`${baseUrl}/teams/${req.teamSlug}` + pageSize)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getSources(callback) {
    return fetch(baseUrl + '/sources')
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getVideosByPlayerSlug(opts, callback) {
    const { slug, pageSize } = opts;
    const pSize = pageSize ? '?pageSize=' + pageSize : '';

    return fetch(baseUrl + '/players/' + slug + pSize)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getVideo(videoId, callback) {
    return fetch(baseUrl + '/' + videoId)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getVideoComments(videoId, callback) {
    return fetch(baseUrl + '/' + videoId + '/comments')
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function postComment(body, callback) {
    return fetch(`${APP_CONFIG.apiUrl}/v1/comments`, {
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        method: 'POST'
    }).then(callback);
}

export function putRatingOnVideo(videoId, body, callback) {
    return fetch(`${baseUrl}/${videoId}/rating`, {
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        method: 'PUT'
    }).then(callback);
}

export function getLikedVideosForUser(callback) {
    return fetch(`${baseUrl}/liked`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}
