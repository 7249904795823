import { APP_CONFIG } from 'config/config';

export function getCurrentUserProfile(callback) {
    fetch(APP_CONFIG.apiUrl + '/v1/profiles').then((response) => {
        if (response.ok) {
            response.json().then((data) => {
                callback(data);
            });
        } else {
            callback({});
        }
    });
}

export function getUserProfile(userId, callback) {
    fetch(APP_CONFIG.apiUrl + '/v1/profiles/' + userId).then((response) => {
        if (response.ok) {
            response.json().then((data) => {
                callback(data);
            });
        } else {
            callback({});
        }
    });
}

export function putProfile(formData, callback) {
    fetch(APP_CONFIG.apiUrl + '/v1/profiles', {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.ok) {
            callback(true);
        } else {
            callback(false);
        }
    });
}
