import { List, ListItem, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTopPages } from '../services/PageviewService';
import Loading from '../lib/Loading';
import { Box } from '@mui/system';
import DataTable from '../lib/DataTable';
import { timeAgo } from 'utils/formatDatetime';

const columns = [
    {
        id: 'path',
        name: 'Page',
        navigateOnClick: (row) => row.path,
        sort: true
    },
    {
        id: 'count',
        name: 'Count',
        sort: true
    },
    {
        id: 'timestamp',
        name: 'Last viewed',
        valueAccessor: (row) => timeAgo(row.timestamp)
    }
];

export default function Popular() {
    const [topPages, setTopPages] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getTopPages((data) => {
            setTopPages(data);
            setLoading(false);
        });
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Box>
                    <h1>Most viewed pages</h1>
                    <DataTable rows={topPages} columns={columns}></DataTable>
                </Box>
            )}
        </>
    );
}
