import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';

const playerWithTeamYearsDisplay = (p) => {
    const teamYears = p.Rosters.map((r) => {
        return {
            teamSlug: r.teamSlug,
            year: r.tournament.date.getFullYear()
        };
    });

    return teamYears
        .reduce((accumulator, currentValue) => {
            // Find the index of the teamSlug in the accumulator
            const index = accumulator.findIndex((team) => team.teamSlug === currentValue.teamSlug);

            if (index >= 0) {
                // If found, append the year to the existing entry
                accumulator[index].year += `, ${currentValue.year}`;
            } else {
                // If not found, add a new entry with the teamSlug and year
                accumulator.push({ teamSlug: currentValue.teamSlug, year: `${currentValue.year}` });
            }

            return accumulator;
        }, [])
        .map((item) => `${item.teamSlug} ${item.year}`); // Start with an empty array as the accumulator
};

export default function Step2(props) {
    return (
        <Box sx={{ my: 4 }}>
            <Typography variant="h6">How should we resolve this collision?</Typography>
            <Box>
                {props.playersWithSameName.length &&
                    'We found the following playerßs with the same name. Please choose one, or select to create a new player instead'}
                <Box sx={{ my: 2, maxWidth: '600px' }}>
                    {props.playersWithSameName.map((p) => {
                        return (
                            <Box
                                key={p.id}
                                sx={{
                                    p: 2,
                                    border: '1px solid grey',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    mb: 2
                                }}
                                onClick={() => props.setSelectedExistingPlayerId(p.id)}>
                                <Box sx={{ mr: 2 }}>
                                    <Radio checked={props.selectedExistingPlayerId === p.id} />
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">{p.name}</Typography>
                                    <Typography variant="caption">
                                        {playerWithTeamYearsDisplay(p).join(', ')}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                    <Box
                        key={'create-new'}
                        sx={{
                            p: 2,
                            border: '1px solid grey',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'baseline'
                        }}
                        onClick={() => props.setSelectedExistingPlayerId(undefined)}>
                        <Box sx={{ mr: 2 }}>
                            <Radio checked={props.selectedExistingPlayerId === undefined} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1">Create new person</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
