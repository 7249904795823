import { APP_CONFIG } from '../config/config';
import { formatDate, formatDateNoYear, formatDatetime } from '../utils/formatDatetime';

const baseUrl = `${APP_CONFIG.apiUrl}/v1/teams`;
const v2Url = `${APP_CONFIG.apiUrl}/v2/teams`;

export function getTeam(request, callback) {
    fetch(`${baseUrl}/${request.id}`)
        .then((response) => response.json())
        .then((team) => {
            team.createdAt = new Date(team.createdAt);
            team.updatedAt = new Date(team.updatedAt);
            callback(team);
        });
}

export function getRostersByTeamId(request, callback) {
    fetch(`${baseUrl}/${request.id}/rosters`)
        .then((response) => response.json())
        .then((data) =>
            callback(
                data
                    .map((roster) => {
                        roster.tournament.date = new Date(roster.tournament.date);
                        roster.year = roster.tournament.date.getFullYear().toString();
                        return roster;
                    })
                    .sort((a, b) => b.tournament.date - a.tournament.date)
            )
        );
}

export function getRosterPlayers(request, callback) {
    fetch(`${baseUrl}/rosters/${request.id}/players`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getPlayerRosters(request, callback) {
    fetch(`${baseUrl}/players/${request.id}/rosters`)
        .then((response) => response.json())
        .then((data) => {
            callback(
                data.map((roster) => {
                    roster.tournament.date = new Date(roster.tournament.date);
                    roster.year = roster.tournament.date.getFullYear();
                    return roster;
                })
            );
        });
}

export function getTeamBySlug(request, callback) {
    fetch(`${v2Url}/${request.slug}`)
        .then((response) => response.json())
        .then((team) => {
            team.createdAt = new Date(team.createdAt);
            team.updatedAt = new Date(team.updatedAt);
            callback(team);
        });
}

export function getTeamRecentResults(request, callback) {
    const page = request.page || 1;
    const pageSize = request.pageSize || 1;

    fetch(`${v2Url}/${request.slug}/recent?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json())
        .then((data) => {
            data.map((result) => {
                result.tournament.date = new Date(result.tournament.date);
                result.tournament.endDate = new Date(result.tournament.endDate);
                result.games = result.games.map((game) => {
                    game.datetime = new Date(game.datetime);
                    return game;
                });
                result.games.sort((a, b) => b.datetime - a.datetime);

                return result;
            });
            callback(data);
        });
}

export function getPlayerBySlug(request, callback) {
    fetch(`${v2Url}/players/${request.slug}`)
        .then((response) => response.json())
        .then((player) => {
            player.createdAt = new Date(player.createdAt);
            player.updatedAt = new Date(player.updatedAt);
            callback(player);
        });
}

export function getTournamentById(request, callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/tournaments/${request.id}`)
        .then((response) => response.json())
        .then((tournament) => {
            tournament.createdAt = new Date(tournament.createdAt);
            tournament.updatedAt = new Date(tournament.updatedAt);
            tournament.date = new Date(tournament.date);
            tournament.endDate = new Date(tournament.endDate);
            tournament.startDateDisplay = formatDate(tournament.date);
            tournament.endDateDisplay = formatDate(tournament.endDate);

            callback(tournament);
        });
}

export function getTournamentBySlug(request, callback) {
    fetch(`${v2Url}/tournaments/${request.slug}`)
        .then((response) => response.json())
        .then((tournament) => {
            tournament.createdAt = new Date(tournament.createdAt);
            tournament.updatedAt = new Date(tournament.updatedAt);
            tournament.date = new Date(tournament.date);
            tournament.endDate = new Date(tournament.endDate);
            tournament.startDateDisplay = formatDate(tournament.date);
            tournament.endDateDisplay = formatDate(tournament.endDate);
            tournament.stages = tournament.stages.map((stage) => {
                stage.groups = stage.groups.map((group) => {
                    group.games = group.games
                        .map((game) => {
                            game.datetime = new Date(game.datetime);
                            game.datetimeDisplay = formatDatetime(game.datetime);
                            return game;
                        })
                        .sort((a, b) => a.datetime - b.datetime);
                    return group;
                });
                return stage;
            });

            callback(tournament);
        });
}

export function getMatchupHistory(request, callback) {
    fetch(`${v2Url}/matchup/${request.team1Id}/${request.team2Id}`)
        .then((response) => response.json())
        .then((data) => {
            if (!data) {
                callback([]);
                return;
            }
            data = data.map((game) => {
                game.datetime = new Date(game.datetime);
                game.datetimeDisplay = formatDatetime(game.datetime);
                return game;
            });
            callback(data);
        });
}

export function getPlayerMatchupHistory(request, callback) {
    fetch(`${v2Url}/player-matchup/${request.player1Id}/${request.player2Id}`)
        .then((response) => response.json())
        .then((data) => {
            if (!data) {
                callback([]);
                return;
            }
            data = data.map((game) => {
                game.datetime = new Date(game.datetime);
                game.datetimeDisplay = formatDatetime(game.datetime);
                return game;
            });
            callback(data);
        });
}

function formatTournamentForHomePage(tournament) {
    tournament.date = new Date(tournament.date);
    tournament.endDate = new Date(tournament.endDate);

    tournament.startDateDisplay = formatDateNoYear(tournament.date);
    tournament.endDateDisplay = formatDateNoYear(tournament.endDate);
    return tournament;
}

export function getUpcomingTournaments(request) {
    return fetch(`${v2Url}/tournaments/upcoming`)
        .then((response) => response.json())
        .then((data) => {
            if (!data) {
                return [];
            }
            return data.map((tournament) => formatTournamentForHomePage(tournament));
        });
}

export function getOngoingTournaments(request) {
    return fetch(`${v2Url}/tournaments/ongoing`)
        .then((response) => response.json())
        .then((data) => {
            if (!data) {
                return [];
            }
            return data.map((tournament) => formatTournamentForHomePage(tournament));
        });
}

export function getRecentlyEndedTournaments(request) {
    return fetch(`${v2Url}/tournaments/past`)
        .then((response) => response.json())
        .then((data) => {
            if (!data) {
                return [];
            }
            return data.map((tournament) => formatTournamentForHomePage(tournament));
        });
}

export function getGameById(request, callback) {
    fetch(`${v2Url}/game/${request.id}`)
        .then((response) => response.json())
        .then((game) => {
            game.datetime = new Date(game.datetime);
            game.datetimeDisplay = formatDatetime(game.datetime);
            callback(game);
        });
}

export function getMostRecentRoster(teamSlug, callback) {
    fetch(`${v2Url}/${teamSlug}/recent-roster`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}

export function getRostersByTeamSlug(teamSlug, callback) {
    fetch(`${v2Url}/${teamSlug}/rosters`)
        .then((response) => response.json())
        .then((data) =>
            callback(
                data
                    .map((roster) => {
                        roster.tournament.date = new Date(roster.tournament.date);
                        roster.year = roster.tournament.date.getFullYear().toString();
                        return roster;
                    })
                    .sort((a, b) => b.tournament.date - a.tournament.date)
            )
        );
}
