import { Grid } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import RecentlyViewed from './RecentlyViewed';
import LikedVideos from './LikedVideos';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export default function ContentLayout(props) {
    const isVideo = useLocation().pathname.includes('video');
    const session = useSessionContext();
    const loggedIn = session.doesSessionExist;

    return (
        <Grid container spacing={4} px={isVideo ? { xs: 1 } : { xs: 1, sm: 2, lg: 8 }}>
            <Grid item xs={12} lg={isVideo ? 12 : 9}>
                <Outlet />
            </Grid>
            <Grid item xs={12} lg={3}>
                <RecentlyViewed name={props.pageTitle} />
                {loggedIn && <LikedVideos />}
            </Grid>
        </Grid>
    );
}
