import React, { useEffect } from 'react';
import { GetScraperHealth, GetSupertokensHealth } from 'services/ServiceHealthService';
import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

export default function ServiceHealthGrid() {
    const [scraperHealth, setScraperHealth] = React.useState({
        status: 'unavailable',
        details: {}
    });

    const [supertokensHealth, setSupertokensHealth] = React.useState({
        status: 'unavailable',
        details: {}
    });
    useEffect(() => {
        GetScraperHealth((res) => {
            setScraperHealth({
                status: 'online',
                details: res
            });
        });
        GetSupertokensHealth((res) => {
            setSupertokensHealth({
                status: res.status
            });
        });
    }, []);

    return (
        <Box sx={{ mt: 4, display: 'flex' }}>
            <Card sx={{ width: '300px', textAlign: 'center', py: 4, mr: 2 }}>
                {scraperHealth.status === 'online' ? (
                    <CheckCircleIcon color="success" fontSize="large" />
                ) : (
                    <WarningIcon color="warning" fontSize="large" />
                )}
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Scraper
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        {scraperHealth.status}
                    </Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Table</b>
                                </TableCell>
                                <TableCell>
                                    <b>Count</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scraperHealth.details &&
                                Object.keys(scraperHealth.details).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{scraperHealth.details[key]}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            <Card sx={{ width: '300px', textAlign: 'center', py: 4 }}>
                {supertokensHealth.status === 200 ? (
                    <CheckCircleIcon color="success" fontSize="large" />
                ) : (
                    <WarningIcon color="warning" fontSize="large" />
                )}
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Supertokens
                    </Typography>

                    <p>{supertokensHealth.status}</p>
                </CardContent>
            </Card>
        </Box>
    );
}
