import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from 'utils/constants';

export function DivisionChip(props) {
    const { division, gender } = props;
    const theme = useTheme();
    const color = theme.palette.gender[GENDER_TRANSFORM[gender].toLowerCase()];

    return (
        <Box
            sx={{
                display: 'inline-block',
                // backgroundColor: theme.palette.primary.main,
                color,
                // border: `1px solid ${color}`,
                // borderRadius: '20px',
                // width: '72px',
                textAlign: 'left',
                // padding: '2px 8px',
                fontSize: '0.8rem',
                fontWeight: 600
            }}>
            {`${division > 1 ? (division === 2 ? 'U20 ' : 'U17 ') : ''}${GENDER_TRANSFORM[gender]}`}
        </Box>
    );
}
