import { Box, Typography } from '@mui/material';

export default function ViewProfile({ profile }) {
    return (
        <Box sx={{ my: 4 }}>
            <Typography variant="h5">Profile</Typography>
            <Typography variant="h6">{profile.name}</Typography>
            <Typography variant="caption">{profile.location}</Typography>
            <p>{profile.bio}</p>
        </Box>
    );
}
