import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Tab,
    Tabs,
    useMediaQuery
} from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getOngoingTournaments,
    getRecentlyEndedTournaments,
    getUpcomingTournaments
} from 'services/TeamService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from 'lib/Loading';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from 'utils/constants';
import { DivisionChip } from 'lib/DivisionChip';
import { useTheme } from '@emotion/react';

const formatTournamentNameForList = (tournamentName) => {
    // remove the year from the tournament name
    const currentYear = new Date().getFullYear();
    return tournamentName.replace(currentYear.toString(), '');
};

const tourneyListColumns = (isMobile) => [
    {
        id: 'date',
        name: 'Date',
        width: isMobile ? '30px' : '110px',
        sx: { pr: 0, mr: 0 },

        sortAccessor: (row) => row.date,
        valueAccessor: (row) =>
            isMobile ? row.startDateDisplay : `${row.startDateDisplay} - ${row.endDateDisplay}`
    },
    {
        id: 'division',
        sx: { textAlign: 'right', px: 0 },
        width: '64px',
        sortAccessor: (row) => row.division,
        valueAccessor: (row) => <DivisionChip division={row.division} gender={row.gender} />
    },
    {
        id: 'name',
        sx: { fontWeight: 600, fontSize: '1rem' },
        name: 'Tournament',
        sortAccessor: (row) => row.name.toLowerCase(),
        valueAccessor: (row) => formatTournamentNameForList(row.name)
    }
];

export default function HomepageTournaments(props) {
    const navigate = useNavigate();
    const [upcomingTournaments, setUpcomingTournaments] = useState([]);
    const [ongoingTournaments, setOngoingTournaments] = useState([]);
    const [recentlyEndedTournaments, setRecentlyEndedTournaments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const columns = tourneyListColumns(!isSm);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        Promise.all([
            getUpcomingTournaments({}),
            getOngoingTournaments({}),
            getRecentlyEndedTournaments({})
        ]).then((values) => {
            setUpcomingTournaments(values[0]);
            setOngoingTournaments(values[1]);
            setRecentlyEndedTournaments(values[2]);
            if (values[1].length) setSelectedTab(1);
            else if (values[0].length >= values[1].length && values[0].length >= values[2].length) {
                setSelectedTab(2);
            } else {
                setSelectedTab(0);
            }
            setLoading(false);
        });
    }, []);
    return loading ? (
        <Loading />
    ) : (
        <>
            <Tabs value={selectedTab} onChange={handleChange}>
                <Tab label={`Recent (${recentlyEndedTournaments.length})`} sx={{ flexGrow: 1 }} />
                {ongoingTournaments.length && (
                    <Tab label={`Ongoing (${ongoingTournaments.length})`} sx={{ flexGrow: 1 }} />
                )}
                <Tab label={`Upcoming (${upcomingTournaments.length})`} sx={{ flexGrow: 1 }} />
            </Tabs>
            <Divider></Divider>
            {selectedTab === 0 ? (
                <DataTable
                    sortHeaderMarginRight={!isSm ? -3 : undefined}
                    sortBy={[columns[0], columns[2], columns[1]]}
                    order="desc"
                    rows={recentlyEndedTournaments}
                    columns={columns}
                    useContainer={true}
                    navigateOnRowClick={(row) => `/tournament/${row.slug}`}></DataTable>
            ) : selectedTab === 1 && ongoingTournaments.length ? (
                <DataTable
                    sortHeaderMarginRight={!isSm ? -3 : undefined}
                    sortBy={[columns[0], columns[2], columns[1]]}
                    order="desc"
                    rows={ongoingTournaments}
                    columns={columns}
                    noDataMessage="No ongoing tournaments"
                    useContainer={true}
                    navigateOnRowClick={(row) => `/tournament/${row.slug}`}></DataTable>
            ) : (
                <DataTable
                    sortHeaderMarginRight={!isSm ? -3 : undefined}
                    sortBy={[columns[0], columns[2], columns[1]]}
                    order="asc"
                    rows={upcomingTournaments}
                    columns={columns}
                    noDataMessage="No upcoming tournaments"
                    useContainer={true}
                    navigateOnRowClick={(row) => `/tournament/${row.slug}`}></DataTable>
            )}
        </>
    );
}
