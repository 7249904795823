// Expecting inputs like the following
// props.data: array of row objects
// props.columns: array of column def objects
// https://www.notion.so/Build-re-usable-datatable-component-05b0f15d8e7840f1a3cd6c1c8366db50

// interface Column {
//     id: string;
//     name: string;
//     valueAccessor?: function;
//     sortAcceessor?: function;
//     navigateOnClick?: function;
//     sort?: boolean;
// }

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    useTheme
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoMenu from './VideoMenu';
import VideoDetailsMenu from 'components/VideoDetailsDialog';

const extractValue = (item, col) => {
    return col?.sortAccessor
        ? col.sortAccessor(item)
        : col?.valueAccessor
        ? col.valueAccessor(item).toLowerCase()
        : item[col.id];
};

const sortFn = (cols, order) => {
    if (!cols) {
        return () => 0;
    }

    if (!Array.isArray(cols)) {
        cols = [cols];
    }

    return (a, b) => {
        let outcome = 0;
        for (let i = 0; i < cols.length; i++) {
            const col = cols[i];
            const aValue = extractValue(a, col);
            const bValue = extractValue(b, col);
            if (aValue > bValue) {
                outcome = 1;
                break;
            } else if (aValue < bValue) {
                outcome = -1;
                break;
            }
        }

        return order === 'desc' ? -outcome : outcome;
    };
};

export default function DataTable(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [order, setOrder] = React.useState(props.order || 'asc');
    const [sortBy, setSortBy] = React.useState();
    const useContainer = props.useContainer ?? true;
    useEffect(() => setSortBy(props.sortBy), [props.sortBy]);

    const onRequestSort = (column) => {
        const isAsc = sortBy === column && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setSortBy(column);
    };

    const colHeaderCells = props.columns.map((col) => {
        return (
            <TableCell key={col.id} sortDirection={sortBy === col.id ? order : false}>
                <TableSortLabel
                    active={sortBy === col}
                    direction={order}
                    sx={{ fontWeight: 700, mr: props.sortHeaderMarginRight }}
                    onClick={(e) => {
                        console.log(e);
                        onRequestSort(col);
                    }}>
                    {col.name}
                </TableSortLabel>
            </TableCell>
        );
    });

    const rows = props.rows.slice();
    var table = (
        <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>{colHeaderCells}</TableRow>
            </TableHead>
            <TableBody>
                {rows?.length ? (
                    rows.sort(sortFn(sortBy, order)).map((row, index) => {
                        const cells = props.columns.map((col) => (
                            <TableCell
                                key={col.id}
                                sx={{
                                    width: col.width || 'auto',
                                    cursor:
                                        col.navigateOnClick || col.onClick ? 'pointer' : 'inherit',
                                    '&:hover': {
                                        color:
                                            col.navigateOnClick || col.onClick
                                                ? theme.palette.primary.dark
                                                : 'inherit'
                                    },
                                    ...col.sx
                                }}
                                onClick={() => {
                                    (col.navigateOnClick && navigate(col.navigateOnClick(row))) ||
                                        (col.onClick && col.onClick(row));
                                }}>
                                <Box
                                    sx={
                                        col.backgroundColor &&
                                        col.backgroundColor(row) !== 'inherit'
                                            ? {
                                                  display: 'inline',
                                                  padding: '2px 8px',
                                                  borderRadius: 1,
                                                  boxShadow:
                                                      'inset 0 0 20px ' + col.backgroundColor(row)
                                              }
                                            : {}
                                    }>
                                    {col.valueAccessor ? col.valueAccessor(row) : row[col.id]}
                                </Box>
                            </TableCell>
                        ));
                        if (row?.videos) {
                            cells.push(
                                <td key={row.id + '-videos'}>
                                    <VideoMenu
                                        sx={{
                                            position: 'absolute',
                                            top: '0px',
                                            right: '8px',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                        videos={row.videos}
                                    />
                                </td>
                            );
                        }

                        if (props?.videoMenu) {
                            cells.push(
                                <td key={row.id + '-report'}>
                                    <VideoDetailsMenu
                                        video={row}
                                        sx={{
                                            position: 'absolute',
                                            top: '0px',
                                            right: '8px',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                    />
                                </td>
                            );
                        }

                        return (
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    position: 'relative',
                                    cursor: props.navigateOnRowClick ? 'pointer' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: props.navigateOnRowClick
                                            ? theme.palette.action.hover
                                            : 'inherit'
                                    }
                                }}
                                onClick={
                                    props.navigateOnRowClick
                                        ? () => navigate(props.navigateOnRowClick(row))
                                        : null
                                }
                                key={row.id || index}>
                                {cells}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell>
                            {props.noDataMessage ? props.noDataMessage : 'No data available'}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
    return useContainer ? (
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
            {table}
        </TableContainer>
    ) : (
        table
    );
}
