import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';
import LightningInvoiceButton from './lightningInvoice';
import { useNavigate } from 'react-router-dom';

export default function Support(props) {
    const navigate = useNavigate();
    return (
        <Box sx={{ maxWidth: '800px' }}>
            <Box>
                <h1>Support for you</h1>
                <p>
                    Found a bug? Report it <Link onClick={() => navigate('/bug-report')}>here</Link>
                </p>

                <p>
                    If you have any questions or concerns, please reach out to us at
                    hello@ultiverse.live
                </p>
            </Box>
            <Box>
                <h1>Support for us</h1>
                <p>
                    We are working super hard to make ultiverse an awesome resource for our
                    community, but we need your help to pay for servers (and more importantly,
                    coffee!) So, if you appreciate what we&apos;re doing, please consider making a
                    donation. We&apos;d be forever grateful!
                </p>

                <h3>Ways to donate</h3>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box>
                        <LightningInvoiceButton />
                        <Box sx={{ textAlign: 'center' }}>
                            <small>
                                <Link onClick={() => navigate('/bitcoin')} component="button">
                                    <em>preferred</em> (why?)
                                </Link>
                            </small>
                        </Box>
                    </Box>
                    <Button
                        sx={{ minHeight: '37px', maxHeight: '37px' }}
                        variant="outlined"
                        href="https://www.paypal.com/donate/?business=5FKXCU9ZDGQQ4&amount=15&no_recurring=1&item_name=Thanks+for+your+support+of+Ultiverse%21&currency_code=USD">
                        USD via PayPal
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
