import { Close, Menu } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function VideoDetailsDialog(props) {
    let params = useParams();
    let navigate = useNavigate();
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} sx={{ minWidth: '350px' }}>
            <DialogTitle sx={{ mr: '150px' }}>Video details</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}>
                <Close />
            </IconButton>
            <List sx={{ pt: 0 }} dense>
                <ListItem>
                    <ListItemText secondary="Title" primary={props.video.title} />
                </ListItem>
                <ListItem>
                    <ListItemText secondary="Tournament" primary={props.video.tournamentName} />
                </ListItem>
                <ListItem>
                    <ListItemText secondary="Team 1" primary={props.video.team1Name} />
                </ListItem>
                <ListItem>
                    <ListItemText secondary="Team 2" primary={props.video.team2Name} />
                </ListItem>
                {props.video.gameRound && (
                    <ListItem>
                        <ListItemText secondary="Game round" primary={props.video.gameRound} />
                    </ListItem>
                )}
                <ListItem>
                    <ListItemText secondary="Source" primary={props.video.source.name} />
                </ListItem>
                <ListItem>
                    <ListItemText
                        secondary="URL"
                        primary={
                            <Link target="_blank" href={props.video.url}>
                                {props.video.url}
                            </Link>
                        }
                    />
                </ListItem>
            </List>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
                <Button color="primary" variant="outlined">
                    Something is incorrect
                </Button>
            </Box> */}
        </Dialog>
    );
}

export default function VideoDetailsMenu(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    return (
        <Box sx={props.sx}>
            <IconButton onClick={handleClickOpen} sx={{ padding: '4px' }}>
                <Menu />
            </IconButton>
            <VideoDetailsDialog open={open} onClose={handleClose} video={props.video} />
        </Box>
    );
}
