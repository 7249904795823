/* eslint-disable no-undef */
import { AppBar, Toolbar, Container, Box, Link } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Search from './Search';
import AdbIcon from '@mui/icons-material/Adb';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

export default function Nav(props) {
    const navigate = useNavigate();
    const darkMode = props.darkMode;
    const setDarkMode = props.setDarkMode;
    const darkModeText = !darkMode ? 'Dark Mode' : 'Light Mode';
    const pages = [
        { title: 'Matchup History', path: '/matchup' },
        { title: 'Support', path: '/support' }
        // { title: 'About', path: '/about' }
        // { title: 'Popular', path: '/popular' }
        // { title: 'Contact', path: '/contact' }
        // { title: 'Sign Up', path: '/auth' }
    ];
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const pageButtonClick = (event, page) => {
        handleCloseNavMenu(event);
        navigate(page.path);
    };

    return (
        <AppBar color="transparent" elevation={0} position="static" sx={{ py: 3 }}>
            <Container maxWidth="100%">
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                (darkMode
                                    ? '/assets/ultiverse-full-logo-dark.svg'
                                    : '/assets/ultiverse-full-logo-light.svg')
                            }
                            alt="Ultiverse"
                            onClick={() => navigate('/')}
                            style={{
                                display: 'block',
                                height: '2rem',
                                cursor: 'pointer'
                            }}
                        />
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                (darkMode
                                    ? '/assets/ultiverse-logo-dark.svg'
                                    : '/assets/ultiverse-logo-light.svg')
                            }
                            alt="Ultiverse"
                            onClick={() => navigate('/')}
                            style={{
                                display: 'block',
                                height: '2rem',
                                cursor: 'pointer'
                            }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 0.7, mx: 2 }}>
                        <Search isResultsPanel={false} />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.title}
                                    onClick={(e) => pageButtonClick(e, page)}
                                    sx={{ my: 2, mx: 1, color: 'inherit', display: 'block' }}>
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="primary">
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' }
                                }}>
                                {pages.map((page) => (
                                    <MenuItem
                                        key={page.title}
                                        onClick={(e) => pageButtonClick(e, page)}>
                                        <Typography textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem onClick={() => setDarkMode(!darkMode)}>
                                    Toggle theme
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                mx: 1
                            }}>
                            <Button
                                sx={{ height: '40px' }}
                                variant="outlined"
                                onClick={() => setDarkMode(!darkMode)}>
                                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
