import { Box, Link, Tooltip } from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getVideosForTeam } from 'services/VideoService';

const videoTableColumns = (slug, navigate) => [
    {
        id: 'tournamentName',
        name: 'Tournament',
        navigateOnClick: (row) => `/tournament/${row.tournamentSlug}`
    },
    {
        id: 'opponent',
        name: 'Opponent',
        navigateOnClick: (row) => {
            return row.team1Slug !== slug
                ? `/team/${row.team1Slug}/roster/${row.roster1ID}`
                : `/team/${row.team2Slug}/roster/${row.roster2ID}`;
        },
        valueAccessor: (row) => {
            return row.team1Slug !== slug ? row.team1Name : row.team2Name;
        }
    },
    {
        id: 'watch',
        name: 'Watch',
        valueAccessor: (row) => {
            return (
                <Tooltip title={row.title} placement="right">
                    <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/video/' + row.id)}>
                        {row.source.name}
                    </Link>
                </Tooltip>
            );
        }
    }
];

export default function TeamVideos(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [slug, setSlug] = useState('');
    const showVideoMenu = props.videoMenu ?? true;

    useEffect(() => {
        const pageSize = props.slug ? 6 : 1000;
        const tSlug = props.slug || params.slug;
        setSlug(tSlug);
        loadVideos(tSlug, pageSize);
        return () => {
            setVideos([]);
        };
    }, [params.slug]);

    const loadVideos = (slug, pageSize) => {
        getVideosForTeam({ teamSlug: slug, pageSize }, (vids) => {
            setVideos(vids);
            setLoading(false);
        });
    };

    return (
        <Box>
            <DataTable
                columns={videoTableColumns(slug, navigate)}
                rows={videos}
                useContainer={!props.slug}
                videoMenu={showVideoMenu}
            />
        </Box>
    );
}
