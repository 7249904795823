import { Box, Divider, Grid, Link, Tooltip, Typography } from '@mui/material';
import Tournament from 'components/tournament/Tournament';
import React from 'react';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from '../utils/constants';
import { formatDatetime } from '../utils/formatDatetime';

const OBJECT_TYPES = {
    team: 1,
    tournament: 2,
    player: 3
};

export default function ObjectHeader(props) {
    let divisionDisplay = '';
    let usauLink = undefined;
    let teamLinks = undefined;
    let teamNickname = undefined;
    let type = props['type'];
    if (type) {
        switch (type) {
            case 'tournament':
                divisionDisplay = (
                    <Box sx={{ mb: 2 }}>
                        {DIVISIONS_TRANSFORM[props.division]} {GENDER_TRANSFORM[props.gender]}{' '}
                        {' | '}
                        {`${props.city || 'unkown'}, ${props.state || 'unknown'}`}
                        {' | '}
                        {props.startDate}
                        {' to '}
                        {props.endDate}
                    </Box>
                );
                usauLink = (
                    <Box>
                        <Link href={props.tournamentUrl} target="_blank">
                            View on USAU
                        </Link>
                    </Box>
                );
                break;
            case 'team':
                divisionDisplay = (
                    <Box sx={{ mb: 2 }}>
                        {DIVISIONS_TRANSFORM[props.division]} {GENDER_TRANSFORM[props.gender]}
                        {props.team.location ? ' | ' : ''}
                        {props.team.location}
                    </Box>
                );
                teamLinks = (
                    <Box>
                        {['Twitter', 'Facebook', 'Website'].map((key) => {
                            const val = props.team[key.toLowerCase()];
                            if (val) {
                                return (
                                    <Tooltip title={val} key={key}>
                                        <Link href={val} target="_blank" key={key} sx={{ ml: 2 }}>
                                            {key}
                                        </Link>
                                    </Tooltip>
                                );
                            }
                        })}
                    </Box>
                );
                teamNickname = props.team.nickname ? ` (${props.team.nickname})` : '';
        }
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <h1>
                    {props['name']}
                    {teamNickname}
                </h1>
                <Box sx={{ minWidth: '20px', display: { xs: 'none', md: 'block' } }}></Box>
                <Box sx={{ flexShrink: 0, display: { xs: 'none', md: 'block' } }}>
                    <small>
                        Created on {props['createdAt']?.toLocaleDateString()} at{' '}
                        {props['createdAt']?.toLocaleTimeString()}
                    </small>
                    <br></br>
                    <small>
                        Updated on {props['updatedAt']?.toLocaleDateString()} at{' '}
                        {props['updatedAt']?.toLocaleTimeString()}
                    </small>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {divisionDisplay}
                {usauLink}
                {teamLinks}
            </Box>

            {!props.noDivider ? <Divider sx={{ mb: 1 }} /> : null}
        </div>
    );
}
