import { AddCircleOutline } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { playerColumns } from 'components/person/Person';
import DataTable from 'lib/DataTable';
import Loading from 'lib/Loading';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createCollision, searchPlayersByName } from 'services/CollisionService';
import { getPlayerBySlug, getPlayerRosters } from 'services/TeamService';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from 'utils/constants';
import Step1 from './steps/Step1';
import Step3 from './steps/Step3';
import Step2 from './steps/Step2';

const columns = [
    {
        id: 'team',
        name: 'Team',
        valueAccessor: (row) => row.team.name,
        navigateOnClick: (row) => `/team/${row.team.slug}/roster/${row.id}`
    },
    {
        id: 'tournament',
        name: 'Tournament',
        valueAccessor: (row) => row.tournament.name,
        navigateOnClick: (row) => `/tournament/${row.tournament.slug}`
    },
    {
        id: 'date',
        name: 'Date',
        valueAccessor: (row) => row.tournament.date.toLocaleDateString(),
        sortAccessor: (row) => row.tournament.date
    }
];

const steps = ['Select rosters to unlink', 'Create or choose existing player', 'Review'];

export default function Collision() {
    const params = useParams();
    const playerSlug = params.slug;
    const navigate = useNavigate();
    const [player, setPlayer] = useState({});
    const [teamYears, setTeamYears] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selection, setSelection] = useState([]);
    const [targetType, setTargetType] = useState(undefined);
    const [playersWithSameName, setPlayersWithSameName] = useState([]);
    const [selectedExistingPlayerId, setSelectedExistingPlayerId] = useState(undefined);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            submit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const updateSelectedTeams = (ids) => {
        const s = [];
        teamYears.forEach((ty) => {
            if (ids.includes(ty.id)) {
                s.push(ty);
            }
        });
        setSelection(s);
    };

    const submit = () => {
        setSubmitting(true);
        const body = {
            playerSlug: playerSlug,
            extractedRostersIds: selection.map((s) => s.rosterIds).flat()
        };

        if (selectedExistingPlayerId) {
            body['newPlayerId'] = selectedExistingPlayerId;
        }

        createCollision(body, (response) => {
            setSubmitted(true);
            setSubmitting(false);
        });
    };

    useEffect(() => {
        if (activeStep === 1) {
            setLoading(true);
            searchPlayersByName(player.name, (players) => {
                setLoading(false);
                setPlayersWithSameName(players.filter((p) => p.slug !== playerSlug));
            });
        } else {
            setLoading(false);
        }
    }, [activeStep]);

    useEffect(() => {
        getPlayerBySlug({ slug: params.slug }, (player) => {
            setPlayer(player);
            getPlayerRosters({ id: player.id }, (rosters) => {
                setLoading(false);
                const sorted = rosters.sort((a, b) => {
                    return b.tournament.date - a.tournament.date;
                });

                const teamYears = [];
                const out = [];

                sorted.forEach((roster) => {
                    const el = {
                        teamName: roster.team.name,
                        year: roster.tournament.date.getFullYear(),
                        teamId: roster.teamId,
                        division: `${DIVISIONS_TRANSFORM[roster.tournament.division]} ${
                            GENDER_TRANSFORM[roster.tournament.gender]
                        }`,
                        id: `${roster.teamId}-${roster.tournament.date.getFullYear()}`
                    };

                    const existing = teamYears.find(
                        (t) => t.teamId === el.teamId && t.year === el.year
                    );
                    if (!existing) {
                        el['rosterIds'] = [roster.id];
                        teamYears.push(el);
                    } else {
                        existing.rosterIds.push(roster.id);
                    }
                });

                setTeamYears(teamYears);
            });
        });
    }, [params.slug]);

    return (
        <Box>
            <Typography sx={{ mb: 2 }} variant="h6">
                Collision resolution for {player.name}
            </Typography>
            <Box sx={{ width: '100%', pr: 2 }}>
                {!(submitted || submitting) ? (
                    <>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === 0 ? (
                                    <Step1
                                        teamYears={teamYears}
                                        initialSelection={selection}
                                        setSelection={updateSelectedTeams}
                                    />
                                ) : null}
                                {activeStep === 1 ? (
                                    <Step2
                                        targetType={targetType}
                                        setTargetType={setTargetType}
                                        playersWithSameName={playersWithSameName}
                                        selectedExistingPlayerId={selectedExistingPlayerId}
                                        setSelectedExistingPlayerId={setSelectedExistingPlayerId}
                                    />
                                ) : null}
                                {activeStep === 2 ? (
                                    <Step3
                                        playerName={player.name}
                                        selected={selection}
                                        selectedExistingPlayerId={selectedExistingPlayerId}
                                    />
                                ) : null}
                            </>
                        )}
                        <Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}>
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button
                                    onClick={handleNext}
                                    variant="outlined"
                                    disabled={activeStep === 0 && selection.length === 0}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </Fragment>
                    </>
                ) : (
                    <Box p={8}>
                        {submitting ? (
                            <Loading />
                        ) : (
                            <Box sx={{ display: 'flex', p: 8, justifyContent: 'center' }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography>
                                        Submitted collision resolution request for {player.name}
                                    </Typography>
                                    <Typography variant="caption">
                                        A staff member will take a look shortly.
                                    </Typography>
                                    <Box sx={{ mt: 4 }}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate('/person/' + playerSlug)}>
                                            Exit
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
