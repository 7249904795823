import React from 'react';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';

export default function AdminRoute(props) {
    return (
        <SessionAuth
            overrideGlobalClaimValidators={(globalValidators) => [
                ...globalValidators,
                UserRoleClaim.validators.includes('admin')
                /* PermissionClaim.validators.includes("modify") */
            ]}>
            <InvalidClaimHandler>{props.children}</InvalidClaimHandler>
        </SessionAuth>
    );
}

function InvalidClaimHandler(props) {
    let sessionContext = useSessionContext();
    if (sessionContext.loading) {
        return null;
    }

    if (sessionContext.invalidClaims.some((i) => i.validatorId === UserRoleClaim.id)) {
        return <div>You cannot access this page because you are not an admin.</div>;
    }

    // We show the protected route since all claims validators have
    // passed implying that the user is an admin.
    return <div>{props.children}</div>;
}

export function useCurrentUser() {
    let sessionContext = useSessionContext();
    if (sessionContext.loading) {
        return null;
    }
    return sessionContext;
}
