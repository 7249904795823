// function that loads a collision by id into a useState variable

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCollision, executeCollision, loadCollision } from 'services/CollisionService';

const cols = [
    {
        id: 'teamSlug',
        name: 'Team'
    }
];

// component to load and display a collision by id with execute button
export default function CollisionDetails() {
    // read id from route
    const { id } = useParams();
    const navigate = useNavigate();

    const [collision, setCollision] = useState(null);
    const confirm = useConfirm();

    useEffect(() => {
        loadCollision(id, (data) => {
            setCollision(data);
            console.log(data);
        });
    }, [id]);

    const execute = (id) => {
        confirm({
            description: 'Are you sure you want to execute this collision resolution?',
            confirmationButtonProps: { color: 'success' },
            cancellationButtonProps: { color: 'error' },
            dialogActionsProps: { sx: { justifyContent: 'space-between' } },
            confirmationText: 'execute'
        })
            .then(() => {
                executeCollision(id, (r) => {
                    console.log(r);
                });
            })
            .catch(() => {
                /* ... */
            });
    };

    const deleteC = (id) => {
        confirm({
            description: 'Are you sure you want to delete collision?',
            confirmationButtonProps: { color: 'error' },
            dialogActionsProps: { sx: { justifyContent: 'space-between' } },
            confirmationText: 'delete'
        })
            .then(() => {
                deleteCollision(id, (r) => {
                    console.log(r);
                    navigate('/admin/collisions');
                });
            })
            .catch(() => {
                /* ... */
            });
    };

    return (
        <div>
            {collision && (
                <div>
                    <h2>{collision.playerSlug}</h2>
                    {collision.executedAt.Valid ? (
                        <Box>
                            <p>
                                Executed at: {new Date(collision.executedAt.Time).toLocaleString()}
                            </p>

                            <p>Target: {collision.newPlayerSlug}</p>
                        </Box>
                    ) : (
                        <p>
                            {collision.newPlayerId ? 'Updating' : 'Creating new'} player with
                            rosters:
                        </p>
                    )}
                    <p>Source: {collision.existingPlayerSlug}</p>

                    <Box>
                        <DataTable rows={collision.extractedRostersIds} columns={cols}></DataTable>
                    </Box>
                    <Box display="flex">
                        <Button variant="outlined" onClick={() => navigate('/admin/collisions')}>
                            Back
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button
                            color="error"
                            sx={{ mr: 2 }}
                            variant="outlined"
                            onClick={() => deleteC(id)}>
                            Delete
                        </Button>
                        <Button
                            color="success"
                            variant="contained"
                            disabled={collision.executedAt.Valid}
                            onClick={() => execute(id)}>
                            Execute
                        </Button>
                    </Box>
                </div>
            )}
        </div>
    );
}
