import { APP_CONFIG } from '../config/config';

export function fuzzySearch(request, callback) {
    const type = request.type ? `&type=${request.type}` : '';
    const pageSize = request.pageSize ? `&pageSize=${request.pageSize}` : '';
    fetch(`${APP_CONFIG.apiUrl}/v2/teams/search?query=${request.input}${type}${pageSize}`)
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        });
}
