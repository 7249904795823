import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    TextField,
    Typography,
    useFormControl
} from '@mui/material';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import Loading from '../lib/Loading';
import getQuerySuggestions, { fuzzySearch } from '../services/SearchService';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from '../utils/constants';

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
};

const transformOptions = (query, options, closeAndNavigate) => {
    if (
        options.people.length === 0 &&
        options.teams.length === 0 &&
        options.tournaments.length === 0
    ) {
        options.noResults = `No results`;
        return options;
    }
    const sx = {
        cursor: 'pointer',
        py: 1,
        px: 2,
        '&:hover': {
            backgroundColor: 'action.hover'
        }
    };
    options.people = options.people.map((p) => (
        <Box
            key={p.slug}
            sx={sx}
            onClick={(e) => {
                closeAndNavigate(`/person/${p.slug}`, p.name);
            }}>
            {p.name}
        </Box>
    ));
    options.teams = options.teams.map((t) => (
        <Box key={t.slug} sx={sx} onClick={() => closeAndNavigate(`/team/${t.slug}`, t.name)}>
            <Box>
                {t.name} {t.nickname ? t.nickname : ''}
            </Box>
            <Box>
                <Typography variant="caption">{`${DIVISIONS_TRANSFORM[t.division]} ${
                    GENDER_TRANSFORM[t.gender]
                }`}</Typography>
            </Box>
        </Box>
    ));
    options.tournaments = options.tournaments.map((t) => (
        <Box key={t.slug} sx={sx} onClick={() => closeAndNavigate(`/tournament/${t.slug}`, t.name)}>
            {t.name}
            <Box>
                <Typography variant="caption">{`${DIVISIONS_TRANSFORM[t.division]} ${
                    GENDER_TRANSFORM[t.gender]
                }`}</Typography>
            </Box>
        </Box>
    ));
    return options;
};

const GroupTitle = ({ title }) => (
    <Typography variant="h6" marginLeft={2}>
        {title}
    </Typography>
);

function SearchPanel({ loading, options, open }) {
    const navigate = useNavigate();

    let cols = 0;
    if (options) {
        if (options.people.length) cols++;
        if (options.teams.length) cols++;
        if (options.tournaments.length) cols++;
    }

    const lgWidth = 340 * cols;
    const lgLeft = cols === 3 ? '-180px' : '0px';

    return open && (loading || options !== undefined) ? (
        <Paper
            elevation={4}
            sx={{
                position: 'absolute',
                top: '60px',
                left: {
                    xs: '-40px',
                    sm: 0,
                    lg: lgLeft
                },
                width: {
                    xs: '80vw',
                    sm: '100%',
                    lg: lgWidth
                },
                minWidth: {
                    xs: '300px',
                    sm: '400px'
                },
                zIndex: 2
            }}>
            {loading ? (
                <Loading></Loading>
            ) : options.noResults ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    height="10vh">
                    <Typography variant="caption">{options.noResults}</Typography>
                </Box>
            ) : (
                <>
                    <Grid container columns={{ xs: 1, sm: 2, lg: 3 }} py={2}>
                        {options.teams.length ? (
                            <Grid item container xs={12} sm lg={12 / cols} flexDirection="column">
                                <GroupTitle title="Teams" />
                                <Divider />
                                {options.teams}
                            </Grid>
                        ) : null}
                        {options.tournaments.length ? (
                            <Grid
                                item
                                container
                                xs={12}
                                sm={12}
                                lg={12 / cols}
                                flexDirection="column">
                                <GroupTitle title="Tournaments" />
                                <Divider />
                                {options.tournaments}
                            </Grid>
                        ) : null}
                        {options.people.length ? (
                            <Grid item container xs={12} lg={12 / cols} flexDirection="column">
                                <GroupTitle title="People" />
                                <Divider />
                                {options.people}
                            </Grid>
                        ) : null}
                    </Grid>
                    {/* <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        mb="1rem">
                        <Button onClick={() => navigate('/results')}>View all results</Button>
                    </Box> */}
                </>
            )}
        </Paper>
    ) : null;
}

export default function Search({ isResultsPanel }) {
    const location = useLocation();
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [inputRef, setInputFocus] = useFocus();
    const wrapperRef = useRef(null);
    const navigate = useNavigate();
    const closeAndNavigate = (path, name) => {
        navigate(path);
        setValue(name);
        setOpen(false);
    };

    const changeHandler = (value) =>
        fuzzySearch({ input: value, pageSize: 12 }, (results) => {
            setOptions(transformOptions(value, results, closeAndNavigate));
            setLoading(false);
        });

    const debouncedValueChangeHandler = useMemo(() => debounce(changeHandler, 500), []);

    useEffect(() => {
        if (value && open) {
            setLoading(true);
            debouncedValueChangeHandler(value);
        }
    }, [value]);

    const clearButtonClick = () => {
        setValue('');
        if (options.noResults) {
            setOptions(undefined);
        }
        setInputFocus();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);

    return (
        <Box
            ref={wrapperRef}
            component="form"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off">
            <FormControl sx={{ width: '100%' }}>
                <InputLabel htmlFor="searchInput">Search</InputLabel>
                <OutlinedInput
                    inputRef={inputRef}
                    id="searchInput"
                    label="Search"
                    onFocus={() => setOpen(true)}
                    placeholder="Enter a team, tournament, or player name"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    endAdornment={
                        value ? (
                            <InputAdornment position="end">
                                <IconButton onClick={clearButtonClick}>
                                    <CloseIcon></CloseIcon>
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                />
                <SearchPanel loading={loading} options={options} open={open}></SearchPanel>
            </FormControl>
        </Box>
    );
}
