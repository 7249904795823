import { useTheme } from '@emotion/react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { VideoTitleAndComments } from 'VideoTitleAndComments';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getGameById,
    getRosterPlayers,
    getTeamBySlug,
    getTournamentById
} from 'services/TeamService';
import { getVideo } from 'services/VideoService';

function PlayerDataTable({ players }) {
    return (
        <DataTable
            columns={[
                {
                    id: 'number',
                    name: '#',
                    width: '20px',
                    sx: { maxWidth: '20px' },
                    valueAccessor: (row) => (row.role === 'coach' ? 'Coach' : row.number),
                    sortAccessor: (row) => parseInt(row.number)
                },
                {
                    id: 'name',
                    name: 'Name',
                    valueAccessor: (row) => row.name,
                    navigateOnClick: (row) => `/person/${row.slug}`
                }
            ]}
            rows={players}></DataTable>
    );
}
function EmbedYoutubeVideo({ video }) {
    const youtubeId = video.url.split('v=')[1];

    return (
        <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
            <iframe
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
                src={'https://www.youtube.com/embed/' + youtubeId}
                title={video?.title}
                frameBorder="0"
                allowFullScreen></iframe>
        </Box>
    );
}

function EmbedVimeoVideo({ video }) {
    const vimeoId = video.url.split('vimeo.com/')[1];
    return (
        <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
            <iframe
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
                src={'https://player.vimeo.com/video/' + vimeoId}
                title={video?.title}
                frameBorder="0"
                allowFullScreen></iframe>
        </Box>
    );
}

function EmbedUltiworldVideo({ video }) {
    const onClick = () => {
        window.open(video.url, 'pagename', 'resizable,height=700,width=1200,left=440,top=300');
    };
    return (
        <Box
            sx={{
                p: 8,
                borderRadius: 8,
                borderStyle: 'dashed',
                borderColor: 'gray',
                borderWidth: 2,
                height: '600px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                mb: 2
            }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="p" sx={{ display: 'block', mb: 2 }}>
                    Ultiworld videos are not embeddable. Click below to open the video player in a
                    popup.
                </Typography>
                <Button variant="contained" onClick={onClick}>
                    Open ultiworld
                </Button>
            </Box>
        </Box>
    );
}

function EmbedVideoByPlatform({ video }) {
    switch (video?.source?.platform?.toLowerCase()) {
        case 'youtube':
            return <EmbedYoutubeVideo video={video} />;
        case 'vimeo':
            return <EmbedVimeoVideo video={video} />;
        case 'ultiworld':
            return <EmbedUltiworldVideo video={video} />;
        default:
            return null;
    }
}

export default function VideoViewer() {
    const params = useParams();
    const [videoDetails, setVideoDetails] = useState(undefined);
    const [userScore, setUserScore] = useState(undefined);
    const [score, setScore] = useState(undefined);
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);
    const [game, setGame] = useState({});
    const [team1, setTeam1] = useState({});
    const [team2, setTeam2] = useState({});
    const [tournament, setTournament] = useState({});
    const theme = useTheme();
    const navigate = useNavigate();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        getVideo(params.id, (data) => {
            setVideoDetails(data.video);
            setUserScore(data.userScore);
            setScore(data.score);
            getRosterPlayers({ id: data.video.roster1ID }, (players) => {
                if (!players?.length) {
                    return;
                }
                const sorted = players.sort((a, b) => {
                    return a.number === 'n/a' ? -1 : parseInt(a.number) - parseInt(b.number);
                });
                setPlayers1(sorted);
            });
            getRosterPlayers({ id: data.video.roster2ID }, (players) => {
                if (!players?.length) {
                    return;
                }
                const sorted = players.sort((a, b) => {
                    return a.number === 'n/a' ? -1 : parseInt(a.number) - parseInt(b.number);
                });
                setPlayers2(sorted);
            });

            getGameById({ id: data.video.game }, (resp) => {
                setGame(resp);
                getTeamBySlug({ slug: resp.roster1.teamSlug }, (resp) => {
                    setTeam1(resp);
                });
                getTeamBySlug({ slug: resp.roster2.teamSlug }, (resp) => {
                    setTeam2(resp);
                });
                getTournamentById({ id: resp.tournamentId }, (resp) => {
                    setTournament(resp);
                });
            });
        });
    }, [params.id]);

    return (
        <Grid container spacing={2}>
            {!isMd && (
                <Grid item xs={12}>
                    <EmbedVideoByPlatform video={videoDetails} />
                    <VideoTitleAndComments
                        score={score}
                        userScore={userScore}
                        setScore={setScore}
                        setUserScore={setUserScore}
                        video={videoDetails}
                        team1={team1}
                        team2={team2}
                        game={game}
                        tournament={tournament}
                    />
                </Grid>
            )}
            <Grid item xs={6} md={2}>
                <Typography
                    variant="h6"
                    sx={{
                        mb: 1,
                        cursor: 'pointer',
                        '&:hover': { color: theme.palette.primary.dark }
                    }}
                    onClick={() => navigate(`/team/${team1.slug}`)}>
                    {team1.name}
                </Typography>
                <PlayerDataTable players={players1} />
            </Grid>

            {isMd && (
                <Grid item xs={0} md={8}>
                    <div>
                        <EmbedVideoByPlatform video={videoDetails} />
                        <VideoTitleAndComments
                            score={score}
                            userScore={userScore}
                            setScore={setScore}
                            setUserScore={setUserScore}
                            video={videoDetails}
                            team1={team1}
                            team2={team2}
                            game={game}
                            tournament={tournament}
                        />
                    </div>
                </Grid>
            )}
            <Grid item xs={6} md={2}>
                <Typography
                    variant="h6"
                    sx={{
                        mb: 1,
                        textAlign: 'end',
                        cursor: 'pointer',
                        '&:hover': { color: theme.palette.primary.dark }
                    }}
                    onClick={() => navigate(`/team/${team2.slug}`)}>
                    {team2.name}
                </Typography>
                <PlayerDataTable players={players2} />
            </Grid>
            {/* {!isMd && (
                <Grid item xs={12}>
                    <VideoTitleAndComments
                        video={videoDetails}
                        team1={team1}
                        team2={team2}
                        game={game}
                        tournament={tournament}
                    />
                </Grid>
            )} */}
        </Grid>
    );
}
