import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Pool from './Pool';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataTable from '../../lib/DataTable';

export const getScoreBackgroundColor = (score1, score2, status, theme) => {
    if (status.toLowerCase() === 'final') {
        return score1 > score2 ? theme.palette.result.win : theme.palette.result.loss;
    } else {
        return 'inherit';
    }
};

export const poolGamesColumns = (theme) => [
    { id: 'datetimeDisplay', name: 'Date/Time', sortAccessor: (row) => row.datetime, width: '20%' },
    {
        id: 'team1Name',
        name: 'Team 1',
        valueAccessor: (row) => row.roster1.team.name,
        navigateOnClick: (row) => `/team/${row.roster1.team.slug}/roster/${row.roster1Id}`
    },
    {
        id: 'score1',
        name: 'Score',
        width: '5%',
        backgroundColor: (row) =>
            getScoreBackgroundColor(row.score1, row.score2, row.status, theme),
        navigateOnClick: (row) => `/game/${row.id}`
    },
    {
        id: 'score2',
        name: 'Score',
        width: '5%',
        backgroundColor: (row) =>
            getScoreBackgroundColor(row.score2, row.score1, row.status, theme),
        navigateOnClick: (row) => `/game/${row.id}`
    },
    {
        id: 'team2Name',
        name: 'Team 2',
        valueAccessor: (row) => row.roster2.team.name,
        navigateOnClick: (row) => `/team/${row.roster2.team.slug}/roster/${row.roster2Id}`
    },
    { id: 'status', name: 'Status', width: '16%' }
    // {
    //     id: 'video',
    //     name: 'Video',
    //     valueAccessor: (row) => (row.videos ? row.videos[0].url : null)
    // }
];

export default function Pools(props) {
    const theme = useTheme();
    const [pools, setPools] = useState([]);

    useEffect(() => {
        setPools(props.stage.groups);
    }, [props.stage]);

    return (
        <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {pools.map((group, index) => (
                    <Pool pool={group} key={index}></Pool>
                ))}
            </Box>
            <Box sx={{ m: 1 }}>
                {pools.map((group, index) => (
                    <Accordion key={index} defaultExpanded={true} sx={{ maxWidth: '900px' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>{group.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, overflow: 'auto' }}>
                            <DataTable
                                columns={poolGamesColumns(theme)}
                                rows={group.games}
                                useContainer={false}></DataTable>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}
