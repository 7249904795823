import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';

export default function About() {
    return (
        <div>
            <Typography variant="h4" sx={{ mb: 2 }}>
                About
            </Typography>
            <small></small>
            <p>
                Ultiverse is a search engine and database for the sport of ultimate. The database
                stores rosters and games dating back to 2012.
            </p>
            <p>
                For information on professional ultimate, check out the{' '}
                <Link href="https://www.theaudl.com" target={'_blank'}>
                    American Ultimate Disc League
                </Link>{' '}
                and{' '}
                <Link href="https://www.premierultimateleague.com/" target={'_blank'}>
                    Premier Ultimate League.
                </Link>
            </p>
            <p>
                Built with React, Golang, and Postgres. Deployed on{' '}
                <Link href="https://railway.app/" target={'_blank'}>
                    Railway
                </Link>
            </p>

            <Box>
                <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                    People
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Anders Juengst
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Founder and Lead Developer
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Matthew McKnight
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Cofounder and Director of Research
                </Typography>
            </Box>

            <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                    Contributors
                </Typography>
                <ul style={{ listStyleType: 'none', padding: 0, marginTop: -8 }}>
                    {['Andrew Li', 'Jason Manning', 'Ella Juengst', 'Daniel Zhu'].map(
                        (contributor, index) => (
                            <li key={index}>
                                <Typography variant="body1">{contributor}</Typography>
                            </li>
                        )
                    )}
                </ul>
            </Box>

            <Typography variant="h4" sx={{ mt: 4 }}>
                Contact
            </Typography>
            <p>Questions? Feature requests? Bug reports?</p>
            <p>Reach out to us at hello@ultiverse.live</p>
        </div>
    );
}
