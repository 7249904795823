import { useTheme } from '@emotion/react';
import { ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { Box, Button, FormControl, Input, InputLabel, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVideoComments, postComment, putRatingOnVideo } from 'services/VideoService';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import SuperTokens from 'supertokens-auth-react';

export function VideoTitleAndComments({
    video,
    team1,
    team2,
    game,
    tournament,
    score,
    userScore,
    setScore,
    setUserScore
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [comments, setComments] = useState([]);
    const session = useSessionContext();
    useEffect(() => {
        if (video?.id) {
            getVideoComments(video?.id, (comments) => setComments(comments));
        }
    }, [video?.id]);

    const addComment = (e) => {
        e.preventDefault();
        const content = e.target[0].value;
        postComment(
            {
                content,
                videoId: video.id
            },
            (resp) => {
                console.log(resp);
            }
        );
    };

    const putRating = (newUserScore) => {
        if (!session.doesSessionExist) {
            SuperTokens.redirectToAuth();
            return;
        }
        putRatingOnVideo(video.id, { score: newUserScore }, () => {
            setUserScore(newUserScore);
            setScore(score + (newUserScore === 1 ? 1 : -1));
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    {team1.name} vs. {team2.name}
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => putRating(userScore === 1 ? 0 : 1)}
                    startIcon={userScore ? <ThumbUp /> : <ThumbUpOutlined />}>
                    {score}
                </Button>
            </Box>
            <Typography
                variant="subtitle1"
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: theme.palette.primary.dark
                    }
                }}
                onClick={() => navigate('/tournament/' + tournament.slug)}>
                {tournament.name}
            </Typography>
            <Typography variant="caption">{game.datetimeDisplay}</Typography>

            {/* <Box sx={{ display: 'flex', gap: 2, mt: 4, flexDirection: 'column' }}>
                <Typography variant="h5">Comments</Typography>
                <form onSubmit={addComment}>
                    <TextField
                        id="comment"
                        name="comment"
                        label="Add comment"
                        // value={formData.name}
                        // onSubmit={addComment}
                        // onChange={handleChange}
                        required
                    />
                    <Button type="submit">Submit</Button>
                </form>

                {comments.map((c) => {
                    return <Box key={c.id}>{c.content}</Box>;
                })}
            </Box> */}
        </>
    );
}
