import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { btcToUsdQuote, generateInvoice } from 'services/LightningService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    useTheme,
    Tooltip,
    CircularProgress
} from '@mui/material';
import { CopyToClipboard } from 'utils/copyToClipboard';
import { APP_CONFIG } from 'config/config';

const wsUrl = `wss://${APP_CONFIG.lightningServiceHost}/`;

export default function LightningInvoiceButton() {
    const [open, setOpen] = useState(false);
    // const [amount, setAmount] = useState('');
    // const [invoice, setInvoice] = useState(null);
    // const [btcUsdQuote, setBtcUsdQuote] = useState(null);
    // const [usdEstimate, setUsdEstimate] = useState(null);
    // const [invoiceConfirmed, setInvoiceConfirmed] = useState(false);
    // const [ws, setWs] = useState(null);
    // const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // setInvoice(null);
        // setAmount('');
        // setUsdEstimate(null);
        // setInvoiceConfirmed(null);
    };

    // const handleAmountClick = (value) => {
    //     setAmount(value);
    // };

    // const handleNextClick = async () => {
    //     try {
    //         generateInvoice(amount, (result) => {
    //             setInvoice(result.invoice);
    //         });
    //     } catch (error) {
    //         console.error('Error generating invoice:', error);
    //     }
    // };

    // useEffect(() => {
    //     btcToUsdQuote((quote) => {
    //         setBtcUsdQuote(quote);
    //     });
    // }, []);

    // useEffect(() => {
    //     if (amount && btcUsdQuote) {
    //         setUsdEstimate(parseFloat(((amount / 100000000) * btcUsdQuote).toFixed(2)));
    //     }
    // }, [amount, btcUsdQuote]);

    // useEffect(() => {
    //     const ws = new WebSocket(wsUrl);

    //     ws.onopen = () => {
    //         if (invoice) {
    //             ws.send(JSON.stringify({ event: 'subscribe_invoice', invoice }));
    //         }
    //     };

    //     ws.onmessage = (event) => {
    //         const message = JSON.parse(event.data);

    //         if (message.event === 'invoice_confirmed') {
    //             setInvoiceConfirmed(true);
    //         }
    //     };

    //     return () => {
    //         ws.close();
    //     };
    // }, [invoice]);

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Bitcoin via Lightning
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <lightning-widget
                        name="Ultiverse"
                        accent="#8633ea"
                        to="anders@getalby.com"
                        image="https://media.primal.net/uploads/f/49/46/f4946001d318320ca3a2c593ac8f2977241dcef7fc8872739feb012e6f188eaf.png"
                        amounts="1000,5000,10000"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}
