import { APP_CONFIG } from 'config/config';

const baseUrl = `${APP_CONFIG.apiUrl}/v1/bug-reports`;

export function createBugReport(request, callback) {
    fetch(`${baseUrl}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then((response) => callback(response));
}

export function listBugReports(callback) {
    fetch(`${baseUrl}/`)
        .then((response) => response.json())
        .then((response) => callback(response));
}
