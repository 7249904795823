import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export default function Loading() {
    return (
        <Box sx={{ my: 8, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={64} />
        </Box>
    );
}
