import React, { useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { postNewVideoSource } from 'services/AdminService';

export default function AddVideoSource(props) {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        channelID: '',
        platform: '',
        scrapingActive: false
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === 'scrapingActive') {
            value = e.target.checked;
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewVideoSource(formData, (resp) => {
            if (resp.error) {
                setError(resp.error);
                return;
            } else {
                setOpen(false);
                props.loadVideos();
            }
        });
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
                Add video source
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add video source</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="url"
                        label="URL"
                        type="text"
                        fullWidth
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="channelID"
                        label="Channel ID"
                        type="text"
                        fullWidth
                        name="channelID"
                        value={formData.channelID}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="platform"
                        label="Platform"
                        type="text"
                        fullWidth
                        name="platform"
                        value={formData.platform}
                        onChange={handleChange}
                    />
                    <FormControlLabel
                        label="Enable scraping?"
                        control={
                            <Checkbox
                                id="scrapingActive"
                                name="scrapingActive"
                                onChange={handleChange}
                            />
                        }></FormControlLabel>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
