import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem
} from '@mui/material';
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { createCollision } from 'services/CollisionService';

export default function CollisionsImport() {
    const [open, setOpen] = useState(false);
    const [collisions, setCollisions] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCollisions([]);
        setUploadProgress(0);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const collisionsJson = JSON.parse(e.target.result);
            setCollisions(collisionsJson.map((collision) => ({ ...collision, status: 'pending' })));

            let progress = 0;
            for (const collision of collisionsJson) {
                setUploadProgress((progress / collisionsJson.length) * 100);
                await createCollision(collision, async (response) => {
                    collision.status = response.status === 201 ? 'success' : 'error';
                    if (response.status !== 201) {
                        const body = await response.json();
                        console.error(body.error);
                    }
                    setCollisions([...collisionsJson]);
                });
                progress++;
            }
            setUploadProgress(100);
        };

        reader.readAsText(file);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" sx={{ mt: 4 }} onClick={handleClickOpen}>
                Import Collisions
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Import Collisions</DialogTitle>
                <DialogContent>
                    {collisions.length === 0 && (
                        <Box sx={{ minWidth: 400 }}>
                            <p>Upload an existing collisions json file</p>
                            <input
                                type="file"
                                accept=".json"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                                id="upload-json"
                                sx={{ minWidth: '300px', minHeight: '100px' }}
                            />
                            <label htmlFor="upload-json">
                                <Button variant="contained" component="span">
                                    Upload JSON
                                </Button>
                            </label>
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <LinearProgress variant="determinate" value={uploadProgress} />
                            )}
                        </Box>
                    )}

                    <List>
                        {collisions.map((collision, index) => (
                            <ListItem key={index}>
                                <Box sx={{ display: 'inline', marginInlineEnd: 2 }}>
                                    {collision.playerSlug}{' '}
                                </Box>
                                {collision.status === 'pending' && <CircularProgress size={20} />}
                                {collision.status === 'success' && (
                                    <CheckCircleIcon style={{ color: 'green' }} />
                                )}
                                {collision.status === 'error' && (
                                    <ErrorIcon style={{ color: 'red' }} />
                                )}
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
