import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Bracket from './Bracket';

function bracketSort(a, b) {
    const numA = parseInt(a.name.match(/\d+/g), 10) || 0;
    const numB = parseInt(b.name.match(/\d+/g), 10) || 0;
    return numA - numB;
}

export default function Brackets(props) {
    const [brackets, setBrackets] = useState([]);
    useEffect(() => {
        props.stage.groups.sort(bracketSort);

        setBrackets(props.stage.groups);
    }, [props.stage]);

    return (
        <Box sx={{ m: 1 }}>
            {brackets.map((group, index) => (
                <Accordion key={index} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography variant="h5">{group.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, overflow: 'auto' }}>
                        <Box sx={{ m: 2 }}>
                            <Bracket group={group} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
