import React from 'react';
import { Box } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Pools from './Pools';
import Brackets from './Brackets';
import Clusters from './Clusters';

function TabPanel(props) {
    const { children, value, index, stage, ...other } = props;

    let child;
    if (stage.type === 0) {
        child = <Pools stage={stage}></Pools>;
    } else if (stage.type === 1) {
        child = <Brackets stage={stage}></Brackets>;
    } else if (stage.type === 2) {
        child = <Clusters stage={stage}></Clusters>;
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 1 }}>{child}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const stagesSort = (a, b) => {
    const poolPlayRegex = /pool play/i;
    const championshipRegex = /(championship|first place|1st place)/i;

    const aHasPoolPlay = poolPlayRegex.test(a.name);
    const bHasPoolPlay = poolPlayRegex.test(b.name);
    const aHasChampionship = championshipRegex.test(a.name);
    const bHasChampionship = championshipRegex.test(b.name);

    if (aHasPoolPlay && bHasPoolPlay) {
        return 0;
    } else if (aHasPoolPlay) {
        return -1;
    } else if (bHasPoolPlay) {
        return 1;
    } else if (aHasChampionship && bHasChampionship) {
        return 0;
    } else if (aHasChampionship) {
        return -1;
    } else if (bHasChampionship) {
        return 1;
    } else {
        return 0;
    }
};

export default function Stages(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const [stages, setStages] = useState([]);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        setStages(props.tournament.stages.sort(stagesSort));
    }, [props.tournament]);

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto">
                    {stages.map((stage, index) => {
                        return <Tab label={stage.name} key={index} {...a11yProps(index)} />;
                    })}
                </Tabs>
            </Box>
            {stages.map((stage, index) => {
                return (
                    <TabPanel value={tabIndex} index={index} key={index} stage={stage}></TabPanel>
                );
            })}
        </Box>
    );
}
