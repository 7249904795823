import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

export default function Step3(props) {
    return (
        <Box sx={{ my: 4 }}>
            <Typography variant="h6">Summary</Typography>
            <Typography variant="body1">
                {`Request to ${props.selectedExistingPlayerId ? 'update' : 'create new'} player "${
                    props.playerName
                }"`}{' '}
                with teams:
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 4, maxWidth: 800 }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Team</TableCell>
                            <TableCell>Division</TableCell>
                            <TableCell>Year</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.selected.map((row, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.teamName}
                                    </TableCell>
                                    <TableCell>{row.division}</TableCell>
                                    <TableCell>{row.year}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
