import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import Loading from 'lib/Loading';
import { useEffect, useState } from 'react';
import { listUsers } from 'services/AdminService';

const columns = [
    { id: 'email', name: 'Email', navigateOnClick: (row) => `/admin/user/${row.id}` },
    { id: 'joined', name: 'Joined', valueAccessor: (row) => row.joined.toLocaleDateString() },
    { id: 'roles', name: 'Roles', valueAccessor: (row) => row.roles.join(', ') }
];

export default function Users() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        listUsers({}, (data) => {
            const userData = data.map((el) => ({
                joined: new Date(el.timeJoined),
                ...el
            }));
            setUsers(userData);
            setLoading(false);
        });
    }, []);

    return (
        <Box>{loading ? <Loading /> : <DataTable columns={columns} rows={users}></DataTable>}</Box>
    );
}
