import { APP_CONFIG } from 'config/config';

export function GetScraperHealth(callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/scraper-health`)
        .then((res) => res.json())
        .then((data) => {
            callback(data);
        });
}

export function GetSupertokensHealth(callback) {
    fetch(`${APP_CONFIG.apiUrl}/v1/roles/health`).then((data) => {
        callback(data);
    });
}
