import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Roster from './Roster';
import { getRostersByTeamSlug } from 'services/TeamService';

const checkForRosterIdInPath = (path) => {
    const parts = path.split('/');
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] === 'roster') {
            return parts[i + 1];
        }
    }
};

export default function TeamRosters() {
    let params = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    const [rosters, setRosters] = useState([]);
    const [selectedRoster, setSelectedRoster] = useState(null);

    useEffect(() => {
        loadRosters();
        return () => {
            setRosters([]);
        };
    }, [params.slug]);

    const loadRosters = () => {
        getRostersByTeamSlug(params.slug, (rosters) => {
            setRosters(rosters);
            const rosterId = checkForRosterIdInPath(location.pathname);
            if (rosterId !== undefined) {
                setSelectedRoster(rosters.find((roster) => roster.id === parseInt(rosterId)));
            }
        });
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ marginBottom: 4 }}>
                <Autocomplete
                    disablePortal
                    disableClearable={true}
                    openOnFocus
                    value={selectedRoster}
                    id="roster-select-autocomplete"
                    groupBy={(option) => option.year}
                    options={rosters}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, newValue, reason) => {
                        if (reason === 'selectOption') {
                            setSelectedRoster(newValue);
                            navigate('/team/' + params.slug + '/roster/' + newValue.id);
                        }
                    }}
                    getOptionLabel={(option) => option.tournament.name}
                    sx={{ width: '460px', mr: 2 }}
                    renderInput={(params) => (
                        <TextField {...params} label="View roster by tournament" />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.tournament.name}
                            </li>
                        );
                    }}
                />

                {selectedRoster ? (
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/tournament/' + selectedRoster.tournament.slug)}>
                        View Tournament
                    </Button>
                ) : null}
            </Stack>

            {!selectedRoster ? <p>Select a tournament to view roster.</p> : null}

            <Outlet></Outlet>
        </Box>
    );
}
