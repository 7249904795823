import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import calculateGameResult from 'utils/findGameWinner';
import { poolGamesColumns } from './Pools';
import VideoMenu from 'lib/VideoMenu';

const roundColumn = {
    id: 'round',
    name: 'Round'
};

export default function Bracket(props) {
    const theme = useTheme();
    const [group, setGroup] = useState(undefined);
    const [trees, setTrees] = useState([]);
    const [error, setError] = useState(undefined);
    const [treeHeight, setTreeHeight] = useState(undefined);
    useEffect(() => {
        setGroup(props.group);
        if (!props.group.games?.length) {
            setError('Bracket TBD');
            return;
        }
        try {
            setTrees(generateBracketTrees(props.group.games, setTreeHeight));
        } catch (e) {
            setError("Error while generating bracket. Here's a list of games instead:");
        }
    }, [props.group]);
    return (
        (error && (
            <Box>
                <Typography sx={{ mb: 1 }}>{error}</Typography>
                {group.games.length ? (
                    <DataTable
                        columns={[...poolGamesColumns(theme), roundColumn]}
                        rows={group.games}
                        useContainer={false}></DataTable>
                ) : (
                    <></>
                )}
            </Box>
        )) || (
            <Box>
                {trees.length ? trees.map((tree, i) => BracketDisplay(tree, treeHeight, i)) : ''}
            </Box>
        )
    );
}

const rosterHasGameInTree = (roster, root) => {
    return (
        root.game.roster1Id === roster ||
        root.game.roster2Id === roster ||
        (root.winnerPrev && rosterHasGameInTree(roster, root.winnerPrev)) ||
        (root.loserPrev && rosterHasGameInTree(roster, root.loserPrev))
    );
};
const rosterHasGameInTrees = (roster, trees) => {
    return trees.some((root) => {
        return rosterHasGameInTree(roster, root);
    });
};

const generateBracketTrees = (games, setTreeHeight) => {
    let gamesByTeam = {};
    games.sort((a, b) => b.datetime - a.datetime);
    games.forEach((game) => {
        let roster1Id = game.roster1Id.toString();
        let roster2Id = game.roster2Id.toString();
        if (!Object.keys(gamesByTeam).includes(roster1Id)) {
            gamesByTeam[roster1Id] = [game];
        } else {
            gamesByTeam[roster1Id].push(game);
        }
        if (!Object.keys(gamesByTeam).includes(roster2Id)) {
            gamesByTeam[roster2Id] = [game];
        } else {
            gamesByTeam[roster2Id].push(game);
        }
    });
    let lastGame = games[0];
    let roots = games.filter((game) => game.datetime.getTime() === lastGame.datetime.getTime());
    let trees = roots.map((root) => recursivelyBuildNodes(root, gamesByTeam, 0, 0, 0));

    Object.keys(gamesByTeam).forEach((rosterId) => {
        if (!rosterHasGameInTrees(parseInt(rosterId), trees)) {
            trees.push(recursivelyBuildNodes(gamesByTeam[rosterId][0], gamesByTeam, 0, 0, 0));
        }
    });

    setTreeHeight(findTreeHeight(trees[0]));
    return trees;
};

const recursivelyBuildNodes = (game, gamesByTeam, round, winnerIndex, loserIndex) => {
    const winner = (game.score1 > game.score2 ? game.roster1Id : game.roster2Id).toString();
    const loser = (game.score1 > game.score2 ? game.roster2Id : game.roster1Id).toString();
    let node = {
        round,
        game
    };
    if (gamesByTeam[winner].length > winnerIndex + 1) {
        node.winnerPrev = recursivelyBuildNodes(
            gamesByTeam[winner][winnerIndex + 1],
            gamesByTeam,
            round + 1,
            winnerIndex + 1,
            0
        );
    }
    if (gamesByTeam[loser].length > loserIndex + 1) {
        node.loserPrev = recursivelyBuildNodes(
            gamesByTeam[loser][loserIndex + 1],
            gamesByTeam,
            round + 1,
            loserIndex + 1,
            0
        );
    }

    return node;
};

const findTreeHeight = (root) => {
    if (!root) {
        return 0;
    }
    const winnerTreeHeight = findTreeHeight(root?.winnerPrev);
    const loserTreeHeight = findTreeHeight(root?.loserPrev);
    return Math.max(winnerTreeHeight, loserTreeHeight) + 1;
};

const gameContianerHeights = {
    1: '128px',
    2: '256px',
    3: '512px',
    4: '768px'
};

function BracketDisplay(bracket, treeHeight, index) {
    const columnWidth = '175px';
    // const minWidth = `${treeHeight * 200}px`;
    let tallestColumn = 0;
    let labels = Array(treeHeight).fill('');
    const columns = [];

    for (let i = 0; i < treeHeight; i++) {
        let round;
        const games = getGamesAtHeight(bracket, i).map((game) => {
            if (!round) {
                round = game.round;
            }
            return <TGame key={game.id} game={game} round={i} />;
        });

        const anyGameHasVideo = games.some((game) => game.props.game.videos?.length);
        const mr = anyGameHasVideo ? 4 : 2;

        tallestColumn = Math.max(tallestColumn, games.length);

        labels.push(
            <Box
                key={i}
                sx={{
                    textAlign: 'center',
                    width: columnWidth,
                    flexShrink: 0,
                    ':not(:last-of-type)': {
                        marginRight: mr
                    }
                }}>
                <Typography>{round}</Typography>
            </Box>
        );

        columns.push(
            <Box
                key={i}
                sx={{
                    width: columnWidth,
                    mr,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    ':not(:last-of-type)': {
                        marginRight: mr
                    }
                }}>
                {games}
            </Box>
        );
    }

    const height = Math.max(
        128 * tallestColumn,
        128 * tallestColumn + Math.log2(tallestColumn) * 64
    );

    return (
        <Box key={index}>
            {index === 0 && <Box sx={{ display: 'flex', mb: 2 }}>{labels.reverse()}</Box>}
            <Box sx={{ display: 'flex', height: `${height}px` }}>{columns.reverse()}</Box>
        </Box>
    );
}

function getGamesAtHeight(node, height) {
    if (!node) return [];
    if (height === 0) {
        return [node.game];
    } else {
        return [
            ...getGamesAtHeight(node.winnerPrev, height - 1),
            ...getGamesAtHeight(node.loserPrev, height - 1)
        ];
    }
}

function TGame(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const game = props.game;
    const winner = game.score1 > game.score2 ? '1' : '2';
    const loser = game.score1 > game.score2 ? '2' : '1';
    const onTeamClick = (roster) => navigate('/team/' + roster.team.slug + '/roster/' + roster.id);
    return (
        <Box sx={{ maxWidth: '260px', minWidth: '150px' }}>
            <Stack sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        my: 0.5,
                        p: 1,
                        backgroundColor: theme.palette.result.win,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onTeamClick(game['roster' + winner])}>
                        {game['roster' + winner].team.name}
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/game/${props.game.id}`)}>
                        {game['score' + winner]}
                    </Box>
                </Box>
                <Box
                    sx={{
                        my: 0.5,
                        p: 1,
                        backgroundColor: theme.palette.result.loss,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onTeamClick(game['roster' + loser])}>
                        {game['roster' + loser].team.name}
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/game/${props.game.id}`)}>
                        {game['score' + loser]}
                    </Box>
                </Box>
                {game.videos ? (
                    <VideoMenu
                        sx={{
                            position: 'absolute',
                            top: '30px',
                            right: '-24px',
                            borderRadius: '20px',
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: 1
                        }}
                        videos={game.videos}
                    />
                ) : undefined}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '-4px' }}>
                    <Typography variant="caption">{game.datetimeDisplay}</Typography>
                    <Typography variant="caption">{game.status}</Typography>
                </Box>
            </Stack>
        </Box>
    );
}
