import { Box, Link } from '@mui/material';

export default function Bitcoin() {
    return (
        <Box>
            <h1>Why do we prefer Bitcoin?</h1>
            <p>
                At Ultiverse, we&apos;re proud to accept and prefer Bitcoin donations via the
                Lightning Network, which offers instant transactions and significantly lower fees
                than traditional electronic payment rails (Paypal takes 63 cents on every $5
                donation!) This technology is nascent, but we believe it has the potential to
                revolutionize the way creators monetize their work.
            </p>
            <h1>Isn&apos;t Bitcoin bad for the environment?</h1>
            <p>
                Bitcoin mining does consume a lot of energy (~0.1-0.6% of global electricity
                depending on the source). However, mining itself doesn&apos;t produce any carbon
                emissions, and as the world continues to transition towards renewables
                Bitcoin&apos;s carbon footprint will continue to decrease.
            </p>
            <p>
                Additionally, Bitcoin mining can actually incentivize clean energy generation. When
                looking to fund a new clean energy plant, a mining company can offer to subsidize
                the plant&apos;s construction in exchange for the right to mine Bitcoin using some
                of the plant&apos;s energy. This is a win-win-win for the mining company, the plant
                operators, and the environment.
            </p>
            <p>
                Ultimately, techonolgy that consumes energy to improve human existance is a good
                thing. Just as we are ok taking oil-powered jetplanes across the ocean, we should be
                ok expending energy on a global, decentralized, permissionless, fair monetary
                system.
            </p>
            <p>Here are some links to learn more about Bitcoin&apos;s impact on the environment</p>
            <ul>
                <li>
                    <Link
                        href="https://www.btcpolicy.org/articles/is-bitcoin-destroying-the-planet"
                        target="blank">
                        <em>Is Bitcoin Destroying the Planet?</em> (Bitcoin Policy Institue)
                    </Link>
                </li>
                <li>
                    <Link
                        href="https://www.bloomberg.com/opinion/articles/2022-05-03/methane-is-a-big-climate-problem-that-bitcoin-can-help-solve"
                        target="blank">
                        <em>Methane is a Big Climate Problem That Bitcoin Can Help Solve</em>{' '}
                        (Bloomberg)
                    </Link>
                </li>
                <li>
                    <Link
                        href="https://sifted.eu/articles/renewable-energy-crypto-mining/"
                        target="blank">
                        <em>Renewable energy is powering the crypto mining boom</em> (Sifted)
                    </Link>
                </li>
            </ul>
        </Box>
    );
}
