import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { listBugReports } from 'services/BugReportService';

const cols = [
    { id: 'createdByEmail', name: 'Created By' },
    { id: 'createdAt', name: 'Created At' },
    {
        id: 'resolved',
        name: 'Resolved',
        valueAccessor: (row) => (row.resolved ? 'Yes' : 'No')
    },
    { id: 'message', name: 'Message' }
];

export default function BugReports() {
    const [reports, setReports] = useState([]);

    useEffect(() => {
        listBugReports((response) => {
            setReports(response);
        });
    }, []);

    return (
        <Box>
            <h1>Bug Reports</h1>
            <DataTable rows={reports} columns={cols}></DataTable>
        </Box>
    );
}
