import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    styled,
    Tooltip,
    Link,
    Tabs,
    Tab
} from '@mui/material';
import { Box } from '@mui/system';
import DataTable from '../../lib/DataTable';
import Loading from '../../lib/Loading';
import ObjectHeader from '../../lib/ObjectHeader';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { getPlayerBySlug, getPlayerRosters } from '../../services/TeamService';
import CareerSummary from '../CareerSummary';
import ReportIssue from '../ReportIssue';
import { getVideosByPlayerSlug } from 'services/VideoService';
import PersonRosters from './PersonRosters';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary
}));

const videoTableColumns = ({ navigate }) => [
    {
        id: 'tournamentName',
        name: 'Tournament',
        navigateOnClick: (row) => `/tournament/${row.tournamentSlug}`
    },
    {
        id: 'title',
        name: 'Title'
    },
    {
        id: 'url',
        name: 'Watch',
        valueAccessor: (row) => {
            return (
                <Tooltip title={row.title} placement="right">
                    <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/video/' + row.id)}>
                        {row.source.name}
                    </Link>
                </Tooltip>
            );
        }
    }
];

const tabs = [{ label: 'Career summary' }, { label: 'Rosters' }, { label: 'Film', to: 'film' }];

export default function Person(props) {
    let params = useParams();
    let navigate = useNavigate();
    const [rosters, setRosters] = useState([]);
    const [playerRosters, setPlayerRosters] = useState([]);
    const [coachRosters, setCoachRosters] = useState([]);
    const [player, setPlayer] = useState({});
    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);

    useEffect(() => {
        setLoading(true);
        getPlayerBySlug({ slug: params.slug }, (player) => {
            setPlayer(player);
            props.setName(player['name']);

            getPlayerRosters({ id: player.id }, (rosters) => {
                const sorted = rosters.sort((a, b) => {
                    return b.tournament.date - a.tournament.date;
                });

                setPlayerRosters(sorted.filter((roster) => roster.role === 'player'));
                setCoachRosters(sorted.filter((roster) => roster.role === 'coach'));

                setRosters(sorted);
                setLoading(false);
            });

            getVideosByPlayerSlug({ slug: params.slug }, (videos) => {
                setVideos(videos);
            });
        });
    }, [params.slug]);

    useEffect(() => {
        const index = tabs.findIndex((tab) => tab.to === location.pathname.split('/')[3]);
        setSelectedTab(index === -1 ? 0 : index);
    }, [location]);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <Box sx={{ mb: 4 }}>
                    <ObjectHeader
                        name={player['name']}
                        createdAt={player.createdAt}
                        updatedAt={player.updatedAt}
                        noDivider={true}></ObjectHeader>

                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        sx={{ mb: 2, borderBottom: '1px solid grey' }}>
                        {tabs.map((tab) => (
                            <Tab key={tab.label} label={tab.label}></Tab>
                        ))}
                    </Tabs>

                    {selectedTab === 0 && (
                        <>
                            <Grid container spacing={2} sx={{ mb: 4 }}>
                                {playerRosters.length ? (
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <h4>Player</h4>
                                            <CareerSummary rosters={playerRosters} />
                                        </Item>
                                    </Grid>
                                ) : undefined}
                                {coachRosters.length ? (
                                    <Grid item xs={12} md={6}>
                                        <Item>
                                            <h4>Coach</h4>
                                            <CareerSummary rosters={coachRosters} />
                                        </Item>
                                    </Grid>
                                ) : undefined}
                            </Grid>
                            <ReportIssue />
                        </>
                    )}

                    {selectedTab === 1 && (
                        <PersonRosters rosters={rosters} player={player}></PersonRosters>
                    )}

                    {selectedTab === 2 && (
                        <Box>
                            <DataTable
                                columns={videoTableColumns({ navigate })}
                                rows={videos}
                                useContainer={true}
                                videoMenu={false}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
}
