import { Button } from '@mui/material';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCollisionJSONForExport, listCollisions } from 'services/CollisionService';
import CollisionsImport from './CollisionsImport';

const cols = [
    {
        id: 'existingPlayerSlug',
        name: 'Source player',
        navigateOnClick: (row) => `/admin/collisions/${row.id}`
    },
    {
        id: 'newPlayerSlug',
        name: 'Target player',
        valueAccessor: (row) => row.newPlayerSlug || 'Create new'
    },
    {
        id: 'executedAt',
        name: 'Executed',
        valueAccessor: (row) =>
            row.executedAt.Valid ? new Date(row.executedAt.Time).toLocaleString() : 'N/A'
    }
];

export default function Collisions(props) {
    const [collisions, setCollisions] = useState([]);

    useEffect(() => {
        listCollisions({}, (response) => {
            setCollisions(response);
        });
    }, []);

    const exportCollisions = () => {
        getCollisionJSONForExport((response) => {
            const element = document.createElement('a');
            const file = new Blob([JSON.stringify(response)], { type: 'application/json' });
            element.href = URL.createObjectURL(file);
            element.download = 'collisions.json';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        });
    };

    return (
        <Box>
            <h1>Collisions</h1>
            <DataTable rows={collisions} columns={cols}></DataTable>

            <Box>
                <Button variant="contained" onClick={() => exportCollisions()}>
                    Export all (JSON Download)
                </Button>
                <CollisionsImport />
            </Box>
        </Box>
    );
}
