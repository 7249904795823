import { AddCircleOutline } from '@mui/icons-material';
import {
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';

export default function Step1(props) {
    const [rows, setRows] = useState([]);
    const [tid, setTid] = useState(null);

    const [selected, setSelected] = useState(props.initialSelection.map((ty) => ty.id));

    useEffect(() => {
        setRows(props.teamYears);
    }, [props.teamYears]);

    useEffect(() => {
        props.setSelection(selected);
    }, [selected]);

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const selectAllOfTeam = (teamId) => {
        const newSelected = selected.concat(
            rows.filter((r) => r.teamId === teamId && !isSelected(r.id)).map((r) => r.id)
        );
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        props.teamYears.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4, maxWidth: 800 }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Team</TableCell>
                            <TableCell>Division</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell align="right">Select</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Tooltip
                                            title={`Select all years of ${row.teamName}`}
                                            enterDelay={25}>
                                            <IconButton
                                                sx={{ padding: 0 }}
                                                aria-label="add-all-of-team"
                                                onClick={() => selectAllOfTeam(row.teamId)}>
                                                <AddCircleOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.teamName}
                                    </TableCell>
                                    <TableCell>{row.division}</TableCell>
                                    <TableCell>{row.year}</TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            sx={{ padding: 0 }}
                                            checked={isItemSelected}
                                            onClick={(event) =>
                                                handleClick(event, row.id)
                                            }></Checkbox>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    );
}
