import './App.scss';
import React from 'react';
import Main, { lightModePalette } from './components/Main';

import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { APP_CONFIG } from './config/config';
import { ConfirmProvider } from 'material-ui-confirm';

SuperTokens.init({
    appInfo: {
        appName: 'Ultidb',
        apiDomain: APP_CONFIG.apiUrl,
        websiteDomain: APP_CONFIG.url,
        apiBasePath: '/v1/auth',
        // eslint-disable-next-line no-undef
        apiGatewayPath: process.env.REACT_APP_ULTIVERSE_ENV === 'production' ? '/api' : undefined,
        websiteBasePath: '/auth'
    },

    getRedirectionURL: (context) => {
        if (context.action === 'TO_AUTH') {
            // Move handling the SIGN_IN_AND_UP action here
            return '/auth';
        }
    },
    recipeList: [
        EmailPassword.init({
            style: `
                [data-supertokens~=container] {
                    --palette-background: 255, 255, 255;
                    --palette-inputBorder: ${lightModePalette.primary.main};
                    --palette-textPrimary: 0, 0, 0;
                    --palette-textInput: ${lightModePalette.primary.main};
                    --palette-textLink: ${lightModePalette.primary.main};
                }
                [data-supertokens~=button] {
                    background-color: ${lightModePalette.primary.main};
                    border-color: ${lightModePalette.primary.main};
                    color: white;
                }
                [data-supertokens~=inputWrapper]:focus-within {
                    border-color: ${lightModePalette.primary.main};
                    box-shadow: rgba(145, 58, 237, 0.25) 0px 0px 0px 0.2rem;        
                }
                [data-supertokens~=link] {
                    color: ${lightModePalette.primary.main};
                }
                [data-supertokens~=inputAdornment] {
                    --palette-primary: ${lightModePalette.primary.main};
                }
            `
        }),
        Session.init()
    ]
});

function App() {
    return (
        <SuperTokensWrapper>
            <ConfirmProvider>
                <Main />
            </ConfirmProvider>
        </SuperTokensWrapper>
    );
}

export default App;
