import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import React from 'react';
import Loading from '../../lib/Loading';
import ObjectHeader from '../../lib/ObjectHeader';
import { Box } from '@mui/system';
import DataTable from '../../lib/DataTable';
import { formatDatetime } from '../../utils/formatDatetime';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getTournamentBySlug } from '../../services/TeamService';
import Stages from './Stages';
import { getVideosForTournament } from 'services/VideoService';

const tournamentColumns = [
    { id: 'datetimeDisplay', name: 'Date/Time', sortAccessor: (row) => row.datetime },
    {
        id: 'team1Name',
        name: 'Team 1',
        valueAccessor: (row) => row.roster1.team.name,
        navigateOnClick: (row) => `/team/${row.roster1.team.slug}/roster/${row.roster1Id}`
    },
    { id: 'score1', name: 'Score', width: '5%' },
    { id: 'score2', name: 'Score', width: '5%' },
    {
        id: 'team2Name',
        name: 'Team 2',
        valueAccessor: (row) => row.roster2.team.name,
        navigateOnClick: (row) => `/team/${row.roster2.team.slug}/roster/${row.roster2Id}`
    }
];

export default function Tournament(props) {
    let params = useParams();
    let navigate = useNavigate();
    const [tournament, setTournament] = useState({});
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [enableBracket, setEnableBracket] = useState(false);
    const [viewBracket, setViewBracket] = useState(false);

    useEffect(() => {
        setViewBracket(false);
        getTournamentBySlug({ slug: params.slug }, (tournament) => {
            props.setName(tournament.name);
            loadVideos(tournament);
        });
    }, [params.slug]);

    const loadVideos = (tournament) => {
        getVideosForTournament(tournament.id, (videos) => {
            if (videos?.length) {
                const tempVideos = {};
                videos.forEach((video) => {
                    if (!tempVideos[video.game]) {
                        tempVideos[video.game] = [];
                    }
                    tempVideos[video.game].push(video);
                });

                tournament.stages.forEach((stage) => {
                    stage.groups.forEach((group) => {
                        group.games.forEach((game) => {
                            if (tempVideos[game.id]) {
                                game.videos = tempVideos[game.id];
                            }
                        });
                    });
                });
            }
            setTournament(tournament);
            setLoading(false);
        });
    };

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <Box>
                    <ObjectHeader
                        name={tournament.name}
                        createdAt={tournament.createdAt}
                        updatedAt={tournament.updatedAt}
                        type="tournament"
                        division={tournament.division}
                        gender={tournament.gender}
                        startDate={tournament.startDateDisplay}
                        endDate={tournament.endDateDisplay}
                        city={tournament.city}
                        state={tournament.state}
                        tournamentUrl={tournament.url}></ObjectHeader>
                    <Stages tournament={tournament} />
                </Box>
            )}
        </div>
    );
}
