import { useTheme } from '@emotion/react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { getScoreBackgroundColor } from 'components/tournament/Pools';
import DataTable from 'lib/DataTable';
import Loading from 'lib/Loading';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTeamRecentResults } from 'services/TeamService';
import { formatDate } from 'utils/formatDatetime';

export const recentResultsColumns = (teamSlug, theme) => [
    {
        id: 'score',
        name: 'Score',
        width: '20%',
        valueAccessor: (row) => {
            return row.roster1.teamSlug !== teamSlug
                ? `${row.score2} - ${row.score1}`
                : `${row.score1} - ${row.score2}`;
        },
        backgroundColor: (row) => {
            return row.roster1.teamSlug !== teamSlug
                ? getScoreBackgroundColor(row.score2, row.score1, row.status, theme)
                : getScoreBackgroundColor(row.score1, row.score2, row.status, theme);
        },
        navigateOnClick: (row) => `/game/${row.id}`
    },
    {
        id: 'opponent',
        name: 'Opponent',
        width: '40%',
        valueAccessor: (row) => {
            return row.roster1.teamSlug !== teamSlug
                ? row.roster1.team.name
                : row.roster2.team.name;
        },
        navigateOnClick: (row) => {
            return row.roster1.teamSlug !== teamSlug
                ? `/team/${row.roster1.teamSlug}/roster/${row.roster1Id}`
                : `/team/${row.roster2.teamSlug}/roster/${row.roster2Id}`;
        }
    },
    {
        id: 'round',
        name: 'Round',
        width: '40%'
    }
];

export default function RecentResults(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const params = useParams();
    const [recentResults, setRecentResults] = useState([]);
    const [recentResultsPage, setRecentResultsPage] = useState(1);
    const [recentResultsLoading, setRecentResultsLoading] = useState(true);

    useEffect(() => {
        const slug = props.slug || params.slug;
        if (!slug) {
            return;
        }
        const pageSize = props.slug ? 1 : 3;

        setRecentResults([]);
        setRecentResultsPage(1);
        setRecentResultsLoading(true);

        getTeamRecentResults({ slug, page: 1, pageSize }, (results) => {
            setRecentResults(results);
            setRecentResultsLoading(false);
        });
    }, [params.slug]);

    const loadMoreRecentResults = () => {
        const pageSize = props.slug ? 1 : 3;
        setRecentResultsLoading(true);
        setRecentResultsPage(recentResultsPage + 1);
        getTeamRecentResults(
            { slug: params.slug, page: recentResultsPage + 1, pageSize },
            (results) => {
                setRecentResults([...recentResults, ...results]);
                setRecentResultsLoading(false);
            },
            () => {
                setRecentResultsPage(recentResultsPage - 1);
            }
        );
    };
    return (
        <Box>
            {recentResults.length === 0 ? (
                recentResultsLoading ? (
                    <Loading />
                ) : (
                    <Box sx={{ p: 2 }}>No recent results.</Box>
                )
            ) : (
                <Box sx={{ mb: 4 }}>
                    {recentResults.map((result) => {
                        const content = (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        mx: 2,
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between'
                                    }}>
                                    <Typography
                                        variant="subtitle1"
                                        onClick={() =>
                                            navigate(`/tournament/${result.tournament.slug}`)
                                        }
                                        sx={{
                                            '&:hover': {
                                                color: theme.palette.primary.dark,
                                                cursor: 'pointer'
                                            },
                                            marginInlineEnd: 1
                                        }}>
                                        {result.tournament.name}
                                    </Typography>
                                    <Typography variant="caption">
                                        {formatDate(result.tournament.date)} -{' '}
                                        {formatDate(result.tournament.endDate)}
                                    </Typography>
                                </Box>
                                <DataTable
                                    columns={recentResultsColumns(params.slug, theme)}
                                    rows={result.games}
                                    useContainer={false}></DataTable>
                            </>
                        );

                        return props.slug ? (
                            <Box key={result.tournament.id}>{content}</Box>
                        ) : (
                            <Paper
                                key={result.tournament.id}
                                sx={{
                                    minWidth: '270px',
                                    mb: 2,
                                    py: 1
                                }}>
                                {content}
                            </Paper>
                        );
                    })}
                    {recentResultsLoading ? (
                        <Loading />
                    ) : !props.slug ? (
                        <Button onClick={() => loadMoreRecentResults()}>Load more results</Button>
                    ) : null}
                </Box>
            )}
        </Box>
    );
}
