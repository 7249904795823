import {
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { getSources, getVideos } from 'services/VideoService';

// title, tournamentName, team1Name, team2Name
const columns = [
    {
        id: 'source',
        name: 'Source',
        valueAccessor: (row) => row.source.name,
        onClick: (row) => {
            window.open(row.source.url, '_blank');
        }
    },
    {
        id: 'title',
        name: 'Title',
        onClick: (row) => {
            window.open(row.url, '_blank');
        }
    },
    {
        id: 'tournamentName',
        name: 'Tournament'
    },
    {
        id: 'team1Name',
        name: 'Team 1'
    },
    {
        id: 'team2Name',
        name: 'Team 2'
    },
    {
        id: 'gameRound',
        name: 'Round'
    },
    {
        id: 'game',
        name: 'Game ID',
        navigateOnClick: (row) => '/game/' + row.game
    }
];

const columnsUnmatched = columns.slice(0, 2);

const PAGE_SIZE_OPTIONS = [100, 500, 1000];

export default function VideoList(props) {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewUnmatched, setViewUnmatched] = useState(false);
    const [sources, setSources] = useState([]);
    const [sourceFilter, setSourceFilter] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        getSources((sources) => {
            setSources(sources);
        });
    }, []);
    useEffect(() => {
        const opts = {
            page: page,
            pageSize: pageSize,
            unmatched: viewUnmatched,
            sourceId: sourceFilter
        };
        setVideos([]);
        setLoading(true);
        getVideos(opts)
            .then((response) => {
                setTotalCount(response.headers.get('X-Total-Count'));
                setPages(Math.ceil(response.headers.get('X-Total-Count') / pageSize));
                return response.json();
            })
            .then((data) => {
                setVideos(data);
                setLoading(false);
            });
    }, [viewUnmatched, sourceFilter, page, pageSize]);

    const handleChange = (e) => {
        setSourceFilter(e.target.value);
    };

    return (
        <Box>
            <FormGroup>
                <FormControlLabel
                    control={<Switch onChange={(e) => setViewUnmatched(e.target.checked)} />}
                    label="View unmatched"
                />
                <FormControl sx={{ width: '300px', my: 2 }}>
                    <InputLabel id="simple-select-label">Source filter</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={sourceFilter}
                        label="Source filter"
                        onChange={handleChange}>
                        <MenuItem key={0} value={0}>
                            {'All'}
                        </MenuItem>
                        {sources.map((source) => (
                            <MenuItem key={source.id} value={source.id}>
                                {source.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormGroup>

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Box>
                        <FormControl size="small" sx={{ mr: 2 }}>
                            <InputLabel id="page-label">Page</InputLabel>
                            <Select
                                value={page}
                                labelId="page-label"
                                label="Page"
                                onChange={(e) => setPage(e.target.value)}>
                                {Array.from(Array(pages).keys()).map((p) => (
                                    <MenuItem key={p} value={p}>
                                        {p + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel id="pageSize-label">Page size</InputLabel>
                            <Select
                                value={pageSize}
                                labelId="page-label"
                                label="Page size"
                                onChange={(e) => setPageSize(e.target.value)}>
                                {PAGE_SIZE_OPTIONS.map((p) => (
                                    <MenuItem key={p} value={p}>
                                        {p}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <span>
                            {videos.length} of {totalCount} videos
                        </span>
                    </Box>
                    <DataTable rows={videos} columns={viewUnmatched ? columnsUnmatched : columns} />
                </>
            )}
        </Box>
    );
}
