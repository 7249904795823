import { useTheme } from '@emotion/react';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';

const genButtonSX = (key, selected, theme) => ({
    backgroundColor: key === selected ? theme.palette.primary.main : 'inherit',
    color: key === selected ? 'white' : 'auto',
    '&:hover': {
        backgroundColor: key === selected ? theme.palette.primary.main : 'inherit'
    }
});

const data = {
    d1women: [
        {
            name: 'Atlantic Coast',
            bids: 2,
            tournamentPath: ''
        },
        {
            name: 'Great Lakes',
            bids: 1,
            tournamentPath: ''
        },
        {
            name: 'Metro East',
            bids: 1
        },
        {
            name: 'New England',
            bids: 3
        },
        {
            name: 'North Central',
            bids: 1
        },
        {
            name: 'Northwest',
            bids: 4
        },
        {
            name: 'Ohio Valley',
            bids: 1
        },
        {
            name: 'South Central',
            bids: 3
        },
        {
            name: 'Southeast',
            bids: 1
        },
        {
            name: 'Southwest',
            bids: 3
        }
    ],
    d1men: [],
    d3women: [],
    d3men: []
};

export default function CollegeNatsQualifiers2023() {
    const [selected, setSelected] = useState('d1women');
    const theme = useTheme();

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h3">College Nationals Qualifiers 2023</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
                It&apos;s the most exciting time of the year in college ultimate! Check below for
                the latest on who&apos;s qualified for nationals:
            </Typography>
            <Box sx={{ display: 'flex', my: 4, justifyContent: 'center' }}>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Button
                        sx={genButtonSX('d1women', selected, theme)}
                        onClick={() => setSelected('d1women')}>
                        D-I Women
                    </Button>
                    <Button
                        sx={genButtonSX('d1men', selected, theme)}
                        onClick={() => setSelected('d1men')}>
                        D-I Men
                    </Button>
                    <Button
                        sx={genButtonSX('d3women', selected, theme)}
                        onClick={() => setSelected('d3women')}>
                        D-III Women
                    </Button>
                    <Button
                        sx={genButtonSX('d3men', selected, theme)}
                        onClick={() => setSelected('d3men')}>
                        D-III Men
                    </Button>
                </ButtonGroup>
            </Box>
        </Box>
    );
}
