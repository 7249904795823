import { Box, Button, Grid } from '@mui/material';
import { TeamAutocomplete } from 'components/Matchups';
import { useState } from 'react';
import { mergeTeamRecords } from 'services/AdminService';
import { getRostersByTeamId, getRostersByTeamSlug } from 'services/TeamService';

export default function MergeTeams() {
    const [team1, setTeam1] = useState(undefined);
    const [team1Rosters, setTeam1Rosters] = useState([]);
    const team1Selected = (team) => {
        setTeam1(team);
        loadRosters(team, setTeam1Rosters);
    };
    const [team2, setTeam2] = useState(undefined);
    const [team2Rosters, setTeam2Rosters] = useState([]);
    const team2Selected = (team) => {
        setTeam2(team);
        loadRosters(team, setTeam2Rosters);
    };

    const [done, setDone] = useState(false);

    const loadRosters = (team, setRosters) => {
        if (!team) return;
        getRostersByTeamSlug(team.slug, (res) => {
            setRosters(res);
        });
    };

    const merge = () => {
        mergeTeamRecords(
            { sourceTeamId: team1.id, targetTeamId: team2.id, targetTeamSlug: team2.slug },
            (res) => {
                if (res.status === 204) {
                    setDone(true);
                } else {
                    alert('Error merging teams');
                }
            }
        );
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <h1>Merge Teams</h1>

                <h2>Merge team: </h2>
                <TeamAutocomplete onChange={team1Selected} />

                <h2>Into team:</h2>
                <TeamAutocomplete onChange={team2Selected} />

                {team1 && team2 && (
                    <Box sx={{ p: 2 }}>
                        {team1.name} will be merged into {team2.name}{' '}
                    </Box>
                )}
                {done ? (
                    <Box sx={{ p: 2 }}>Done!</Box>
                ) : (
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={() => merge()}
                        disabled={!(team1 && team2)}>
                        Submit
                    </Button>
                )}
            </Grid>
            <Grid item xs={8}>
                <h2>Team 1 rosters</h2>
                <ul>
                    {team1Rosters.map((roster) => (
                        <li key={roster.id}>{roster.tournament.name}</li>
                    ))}
                </ul>

                <h2>Team 2 rosters</h2>
                <ul>
                    {team2Rosters.map((roster) => (
                        <li key={roster.id}>{roster.tournament.name}</li>
                    ))}
                </ul>
            </Grid>
        </Grid>
    );
}
