import { APP_CONFIG } from 'config/config';

const baseUrl = APP_CONFIG.lightningServiceHost;

export function generateInvoice(amount, callback) {
    fetch(`https://strike.me/.well-known/lnurlp/andersj/?amount=${amount}`, {
        method: 'POST',
        body: JSON.stringify({ satoshis: amount }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        response.json().then((data) => {
            callback(data);
        });
    });
}

export function btcToUsdQuote(callback) {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd')
        .then((response) => response.json())
        .then((data) => {
            callback(data.bitcoin.usd);
        });
}
