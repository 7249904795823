import { useEffect, useState } from 'react';
import React from 'react';
import DataTable from '../lib/DataTable';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';

const columns = [
    {
        id: 'recentlyviewed',
        name: 'Recently Viewed',
        valueAccessor: (row) => row.name,
        navigateOnClick: (row) => row.path
    }
];

function arrayContains(arr, obj) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].path === obj.path) {
            return true;
        }
    }
    return false;
}

function moveToTop(arr, obj) {
    let i = 0;
    while (i < arr.length) {
        if (arr[i].path === obj.path) {
            break;
        }
        i++;
    }
    let temp = Array.from(arr);
    temp.splice(i, 1);
    return [obj, ...temp];
}

function storeHistoryForPath(pathname) {
    const paths = ['/team', '/tournament', '/game', '/person'];
    for (let i = 0; i < paths.length; i++) {
        if (pathname.startsWith(paths[i])) {
            return true;
        }
    }
    return false;
}

const teamPathRegex = /\/team\/[^/]+/;

export default function RecentlyViewed(props) {
    const location = useLocation();
    const [history, setHistory] = useState([]);
    const [title, setTitle] = useState('');

    useEffect(() => {
        const historyObj = {
            path: location.pathname,
            name: title
        };
        if (!storeHistoryForPath(location.pathname)) {
            return;
        } else if (location.pathname.startsWith('/team')) {
            historyObj.path = location.pathname.match(teamPathRegex)[0];
        }
        if (arrayContains(history, historyObj)) {
            var temp = moveToTop(history, historyObj);
            setHistory(temp);
        } else if (history.length < 15) {
            setHistory([historyObj, ...history]);
        } else {
            let temp = Array.from(history);
            temp.pop();
            setHistory([historyObj, ...temp]);
        }
    }, [title]);

    useEffect(() => {
        setTitle(props.name);
    }, [props.name]);

    useEffect(() => {
        let sessionHistory = JSON.parse(sessionStorage.getItem('history'));
        if (sessionHistory !== null) {
            sessionStorage.setItem('history', sessionHistory);
            setHistory(sessionHistory);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('history', JSON.stringify(history));
    }, [history]);

    return history.length > 0 ? (
        <Box sx={{ width: '100%' }}>
            <DataTable rows={history} columns={columns} />
        </Box>
    ) : null;
}
