import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { fuzzySearch } from 'services/SearchService';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from 'utils/constants';
import {
    getMatchupHistory,
    getPlayerBySlug,
    getPlayerMatchupHistory,
    getTeam,
    getTeamBySlug
} from 'services/TeamService';
import DataTable from 'lib/DataTable';
import { getScoreBackgroundColor } from './tournament/Pools';
import { useTheme } from '@emotion/react';

export const gamesColumns = (theme, player1Name = undefined, player2Name = undefined) => [
    { id: 'datetimeDisplay', name: 'Date/Time', sortAccessor: (row) => row.datetime },
    {
        id: 'tournamentName',
        name: 'Tournament',
        navigateOnClick: (row) => `/tournament/${row.tournamentSlug}`
    },
    {
        id: 'team1Name',
        name: player1Name || 'Team 1',
        navigateOnClick: (row) => `/team/${row.team1Slug}`
    },
    {
        id: 'score1',
        name: 'Score',
        width: '5%',
        backgroundColor: (row) => getScoreBackgroundColor(row.score1, row.score2, row.status, theme)
    },
    {
        id: 'score2',
        name: 'Score',
        width: '5%',
        backgroundColor: (row) => getScoreBackgroundColor(row.score2, row.score1, row.status, theme)
    },
    {
        id: 'team2Name',
        name: player2Name || 'Team 2',
        navigateOnClick: (row) => `/team/${row.team2Slug}`
    }
];

export default function Matchups() {
    const [team1, setTeam1] = React.useState(undefined);
    const team1Selected = (team) => setTeam1(team);
    const [team2, setTeam2] = React.useState(undefined);
    const team2Selected = (team) => setTeam2(team);
    const [message, setMessage] = React.useState('Select two teams to view their matchup history');
    const [games, setGames] = React.useState([]);

    const [player1, setPlayer1] = React.useState(undefined);
    const [player2, setPlayer2] = React.useState(undefined);

    const [matchupType, setMatchupType] = React.useState('');

    const theme = useTheme();

    const handleMatchupTypeChange = (event) => {
        const newMatchupType = event.target.value;
        const url = new URL(window.location.href);
        url.searchParams.set('type', newMatchupType);
        url.searchParams.delete('team1');
        url.searchParams.delete('team2');
        url.searchParams.delete('player1');
        url.searchParams.delete('player2');
        window.history.replaceState({}, '', url);
        setMatchupType(newMatchupType);
        setGames([]);
    };

    React.useEffect(() => {
        if (matchupType !== 'team') return;
        if (team1 && team2) {
            const url = new URL(window.location.href);
            url.searchParams.set('team1', team1.slug);
            url.searchParams.set('team2', team2.slug);
            window.history.replaceState({}, '', url);
            getMatchupHistory({ team1Id: team1.id, team2Id: team2.id }, (response) => {
                if (response?.length) {
                    setGames(response);
                } else {
                    setGames([]);
                    setMessage('No games found between these teams.');
                }
            });
        } else {
            setGames([]);
            setMessage('Select two teams to view their matchup history');
        }
    }, [team1, team2, matchupType]);

    React.useEffect(() => {
        if (matchupType !== 'player') return;

        if (player1 && player2) {
            const url = new URL(window.location.href);
            url.searchParams.set('player1', player1.slug);
            url.searchParams.set('player2', player2.slug);
            window.history.replaceState({}, '', url);
            getPlayerMatchupHistory(
                { player1Id: player1.id, player2Id: player2.id },
                (response) => {
                    if (response?.length) {
                        setGames(response);
                    } else {
                        setGames([]);
                        setMessage('No games found between these players.');
                    }
                }
            );
        } else {
            setGames([]);
            setMessage('Select two players to view their matchup history');
        }
    }, [player1, player2, matchupType]);

    React.useEffect(() => {
        // check url for team1/team2 or player1/player2 params
        const url = new URL(window.location.href);
        const type = url.searchParams.get('type');
        if (type) {
            setMatchupType(type);
        }
        const team1Slug = url.searchParams.get('team1');
        const team2Slug = url.searchParams.get('team2');
        const player1Slug = url.searchParams.get('player1');
        const player2Slug = url.searchParams.get('player2');
        if (type === 'team' && team1Slug && team2Slug) {
            getTeamBySlug({ slug: team1Slug }, (team) => {
                setTeam1(team);
            });
            getTeamBySlug({ slug: team2Slug }, (team) => {
                setTeam2(team);
            });
        }
        if (type === 'player' && player1Slug && player2Slug) {
            getPlayerBySlug({ slug: player1Slug }, (player) => {
                setPlayer1(player);
            });
            getPlayerBySlug({ slug: player2Slug }, (player) => {
                setPlayer2(player);
            });
        }
    }, []);
    return (
        <Box>
            <h1>Matchup history</h1>

            <FormControl sx={{ width: '200px', mb: 4 }}>
                <InputLabel id="demo-simple-select-label">Matchup type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={matchupType}
                    label="Matchup type"
                    onChange={handleMatchupTypeChange}>
                    <MenuItem value={'team'}>Teams</MenuItem>
                    <MenuItem value={'player'}>Players</MenuItem>
                </Select>
            </FormControl>

            <>
                <Box
                    sx={{
                        display: matchupType === 'player' ? 'flex' : 'none',
                        alignItems: 'center',
                        mb: 4
                    }}>
                    <PlayerAutocomplete onChange={setPlayer1} initialPlayer={player1} />
                    <Typography sx={{ mx: 4 }} variant="body">
                        vs
                    </Typography>
                    <PlayerAutocomplete onChange={setPlayer2} initialPlayer={player2} />
                </Box>
            </>

            <Box
                sx={{
                    display: matchupType === 'team' ? 'flex' : 'none',
                    alignItems: 'center',
                    mb: 4
                }}>
                <TeamAutocomplete onChange={team1Selected} initialTeam={team1} />
                <Typography sx={{ mx: 4 }} variant="body">
                    vs
                </Typography>
                <TeamAutocomplete onChange={team2Selected} initialTeam={team2} />
            </Box>
            {games.length ? (
                <DataTable
                    columns={
                        matchupType === 'player'
                            ? gamesColumns(theme, player1?.name, player2?.name)
                            : gamesColumns(theme)
                    }
                    sortBy={gamesColumns(theme)[0]}
                    order="desc"
                    rows={games}
                    useContainer={true}></DataTable>
            ) : (
                <p>{message}</p>
            )}
        </Box>
    );
}

export function PlayerAutocomplete(props) {
    const { onChange, initialPlayer } = props;
    const [value, setValue] = React.useState(initialPlayer || null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const loaded = React.useRef(false);

    const changeHandler = (value) =>
        fuzzySearch({ input: value, type: 'players' }, (results) => {
            setOptions(results.people || []);
            setLoading(false);
        });

    const debouncedValueChangeHandler = React.useMemo(() => debounce(changeHandler, 500), []);
    React.useEffect(() => {
        if (value && inputValue == value.name) {
            return;
        }
        if (!inputValue) {
            setOptions([]);
            return;
        }
        if (inputValue && open) {
            setLoading(true);
            debouncedValueChangeHandler(inputValue);
        }
    }, [inputValue, value]);

    React.useEffect(() => {
        onChange(value);
    }, [value]);

    React.useEffect(() => {
        if (initialPlayer && options.length === 0) {
            setOptions([initialPlayer]);
            setValue(initialPlayer);
        }
    }, [initialPlayer]);

    return (
        <Autocomplete
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value}
            filterSelectedOptions
            noOptionsText="No players"
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Player search"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    fullWidth
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
        />
    );
}

export function TeamAutocomplete(props) {
    const { onChange, initialTeam } = props;
    const [value, setValue] = React.useState(initialTeam || null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const loaded = React.useRef(false);

    const changeHandler = (value) =>
        fuzzySearch({ input: value, type: 'teams' }, (results) => {
            setOptions(results.teams || []);
            setLoading(false);
        });

    const debouncedValueChangeHandler = React.useMemo(() => debounce(changeHandler, 500), []);
    React.useEffect(() => {
        if (value && inputValue == value.name) {
            return;
        }
        if (!inputValue) {
            setOptions([]);
            return;
        }
        if (inputValue && open) {
            setLoading(true);
            debouncedValueChangeHandler(inputValue);
        }
    }, [inputValue, value]);

    React.useEffect(() => {
        onChange(value);
    }, [value]);

    React.useEffect(() => {
        if (initialTeam && options.length === 0) {
            setOptions([initialTeam]);
            setValue(initialTeam);
        }
    }, [initialTeam]);

    return (
        <Autocomplete
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value}
            filterSelectedOptions
            noOptionsText="No teams"
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Team search"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    fullWidth
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}{' '}
                    {`| ${DIVISIONS_TRANSFORM[option.division]} ${GENDER_TRANSFORM[option.gender]}`}
                </li>
            )}
        />
    );
}
