import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { getRosterPlayers } from '../../services/TeamService';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from '../../lib/DataTable';
import Loading from '../../lib/Loading';

export default function Roster(props) {
    let params = useParams();
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    useEffect(() => {
        const id = props.rosterId || params.rosterId;
        if (!id) {
            return;
        }
        loadPlayers(id);

        return () => {
            setPlayers([]);
        };
    }, [params.rosterId, props?.rosterId]);

    const loadPlayers = (id) => {
        getRosterPlayers({ id }, (players) => {
            if (!players?.length) {
                setErrorMsg('No players found for this roster');
                setLoading(false);
                return;
            } else if (errorMsg) {
                setErrorMsg('');
            }
            const sorted = players.sort((a, b) => {
                return a.number === 'n/a' ? -1 : parseInt(a.number) - parseInt(b.number);
            });
            setPlayers(sorted);
            setLoading(false);
        });
    };

    return (
        <Box>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="caption">{errorMsg}</Typography>
                </Box>
            ) : (
                <DataTable
                    useContainer={!props?.rosterId}
                    columns={[
                        {
                            id: 'number',
                            name: 'Number',
                            width: '5%',
                            valueAccessor: (row) => (row.role === 'coach' ? 'Coach' : row.number),
                            sortAccessor: (row) => parseInt(row.number)
                        },
                        {
                            id: 'name',
                            name: 'Name',
                            valueAccessor: (row) => row.name,
                            navigateOnClick: (row) => `/person/${row.slug}`
                        }
                    ]}
                    rows={players}></DataTable>
            )}
        </Box>
    );
}
