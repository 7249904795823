import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import Loading from 'lib/Loading';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addRole, getUser, listUsers, removeRole } from 'services/AdminService';
import { CircularProgress, InputAdornment } from '@mui/material';
import { getUserProfile } from 'services/UserService';
import ViewProfile from 'components/profile/ViewProfile';

export default function User() {
    const userId = useParams().id;
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [currentRole, setCurrentRole] = useState('user');

    useEffect(() => {
        getUser({ id: userId }, (data) => {
            setUser(data);
            if (data.roles.includes('admin')) {
                setCurrentRole('admin');
            } else if (data.roles.includes('moderator')) {
                setCurrentRole('moderator');
            }
            setLoading(false);
        });
        getUserProfile(userId, (data) => {
            setProfile(data);
            console.log(data);
        });
    }, []);

    const handleChange = (event) => {
        setSaving(true);
        if (event.target.value === 'user') {
            removeRole({ UserId: userId, Role: currentRole }, (response) => {
                if (response.ok) {
                    setCurrentRole(event.target.value);
                    setSaving(false);
                }
            });
        } else {
            addRole({ UserId: userId, Role: event.target.value }, (response) => {
                if (response.ok) {
                    setCurrentRole(event.target.value);
                    setSaving(false);
                }
            });
        }
    };

    return (
        <Box>
            {loading ? (
                <Loading />
            ) : (
                <Box>
                    <h2>{user.email}</h2>
                    <FormControl sx={{ width: '200px' }}>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            label="Role"
                            disabled={currentRole === 'admin'}
                            value={currentRole}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    {saving ? (
                                        <CircularProgress
                                            color="primary"
                                            size={20}
                                            sx={{ mr: 3 }}
                                        />
                                    ) : null}
                                </InputAdornment>
                            }>
                            <MenuItem value={'user'}>User</MenuItem>
                            <MenuItem value={'moderator'}>Moderator</MenuItem>
                            <MenuItem value={'admin'}>Admin</MenuItem>
                        </Select>
                    </FormControl>

                    <ViewProfile profile={profile} />
                </Box>
            )}
        </Box>
    );
}
