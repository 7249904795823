import { Link } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
    const navigate = useNavigate();
    return (
        <>
            <Box p={4}>
                <h2>Oops! We couldn&apos;t find that page</h2>
                <p>
                    If you are looking for a specific team, player or tournament, trying searching
                    for them instead.
                </p>
                <p>
                    Or, click{' '}
                    <Link sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                        here
                    </Link>{' '}
                    to go back to the homepage.
                </p>
            </Box>
        </>
    );
}
