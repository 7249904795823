const options = {
    timeStyle: 'short',
    dateStyle: 'short',
    timeZone: 'Etc/GMT'
};

const dtFormat = new Intl.DateTimeFormat('en-US', options);
const dFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeZone: 'Etc/GMT' });
const dFormatNoYear = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    timeZone: 'Etc/GMT'
});

export function formatDatetime(date) {
    return dtFormat.format(date);
}

export function formatDate(date) {
    return dFormat.format(date);
}

export function formatDateNoYear(date) {
    return dFormatNoYear.format(date);
}

export function timeAgo(isoDate) {
    const now = new Date();
    const past = new Date(isoDate);
    const secondsAgo = Math.floor((now - past) / 1000);

    if (secondsAgo < 60) {
        return 'Just now';
    } else if (secondsAgo < 3600) {
        return Math.floor(secondsAgo / 60) + ' minutes ago';
    } else if (secondsAgo < 86400) {
        return Math.floor(secondsAgo / 3600) + ' hours ago';
    } else if (secondsAgo < 604800) {
        return Math.floor(secondsAgo / 86400) + ' days ago';
    } else if (secondsAgo < 2419200) {
        return Math.floor(secondsAgo / 604800) + ' weeks ago';
    } else {
        return Math.floor(secondsAgo / 2419200) + ' months ago';
    }
}
