import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { getLikedVideosForUser } from 'services/VideoService';

export default function LikedVideos() {
    const [likedVideos, setLikedVideos] = useState([]);
    useEffect(() => {
        getLikedVideosForUser((videos) => setLikedVideos(videos));
    }, []);

    return (
        <DataTable
            columns={[
                { name: 'Liked Videos', id: 'title', navigateOnClick: (row) => `/video/${row.id}` }
            ]}
            rows={likedVideos}
        />
    );
}
