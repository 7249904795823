import { Box, Button, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import ReportIssue from './ReportIssue';

const getNameDisplay = (roster, navigate) => {
    return (
        <Box key={roster.team.id}>
            <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/team/${roster.team.slug}/roster/${roster.id}`)}>
                {roster.team.name}
            </Link>{' '}
            {DIVISIONS_TRANSFORM[roster.team.division]} {GENDER_TRANSFORM[roster.team.gender]}
        </Box>
    );
};

const findTeamsByYear = (rosters, navigate) => {
    let years = {};
    for (let i = 0; i < rosters.length; i++) {
        let year = rosters[i].tournament.date.getFullYear();
        if (years[year] === undefined) {
            years[year] = {
                ids: [rosters[i].teamId],
                names: [getNameDisplay(rosters[i], navigate)]
            };
        } else {
            if (!years[year].ids.includes(rosters[i].teamId)) {
                years[year].ids.push(rosters[i].teamId);
                years[year].names.push(getNameDisplay(rosters[i], navigate));
            }
        }
    }

    const result = Object.keys(years)
        .map((year) => {
            return { year, ...years[year] };
        })
        .reverse();
    return result;
};

export default function CareerSummary({ rosters }) {
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);

    useEffect(() => {
        setData(findTeamsByYear(rosters, navigate));
    }, [rosters]);

    return data ? (
        <Box sx={{ mb: 4 }}>
            <Timeline>
                {data.map((year, index) => (
                    <TimelineItem key={year.year}>
                        <TimelineOppositeContent sx={{ width: '100px', flex: 0 }}>
                            {year.year}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            {index < data.length - 1 ? <TimelineConnector /> : null}
                        </TimelineSeparator>
                        <TimelineContent>{year.names}</TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    ) : null;
}
