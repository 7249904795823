import React, { useEffect, useState } from 'react';
import { getCurrentUserProfile, putProfile } from 'services/UserService';
import { Box } from '@mui/system';
import { TextField, Button } from '@mui/material';
import { useCurrentUser } from 'lib/AdminRoute';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';

const UserProfileForm = () => {
    let sessionContext = useSessionContext();

    if (sessionContext.loading) {
        return null;
    }

    const [formData, setFormData] = useState({
        name: '',
        bio: '',
        location: ''
    });

    useEffect(() => {
        getCurrentUserProfile((data) => {
            if (!data.userId) {
                return;
            }
            setFormData({ ...data });
        });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putProfile(formData, (success) => {
            if (success) {
                alert('Profile updated successfully');
            } else {
                alert('Failed to update profile');
            }
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 'sm' }}>
                <h2>User Profile</h2>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <TextField
                    id="bio"
                    name="bio"
                    label="Bio"
                    value={formData.bio}
                    onChange={handleChange}
                />

                <TextField
                    id="location"
                    name="location"
                    label="Location"
                    value={formData.location}
                    onChange={handleChange}
                />

                <Button type="submit" variant="contained">
                    Save
                </Button>
            </Box>
        </form>
    );
};

export default UserProfileForm;
