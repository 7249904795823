import {
    Button,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function SimpleDialog(props) {
    let params = useParams();
    let navigate = useNavigate();
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} sx={{ minWidth: '350px' }}>
            <DialogTitle sx={{ mr: '150px' }}>Tell us about this issue:</DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItem disableGutters>
                    <ListItemButton onClick={() => navigate('/collisions/' + params.slug)}>
                        <ListItemText
                            primary="Name collision"
                            secondary="Page contains data from multiple players with the same name"
                        />
                    </ListItemButton>
                </ListItem>

                <ListItem disableGutters>
                    <ListItemButton autoFocus onClick={() => navigate('/bug-report')}>
                        <ListItemText primary="Bug report" secondary="Something else" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
}

export default function ReportIssue(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Report an issue
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </div>
    );
}
