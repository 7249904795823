import { Box, Typography } from '@mui/material';
import { borderRadius } from '@mui/system';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DataTable from '../../lib/DataTable';

const poolColumns = [
    {
        id: 'name',
        name: 'Team',
        width: '80%',
        navigateOnClick: (row) => `/team/${row.slug}/roster/${row.rosterId}`
    },
    {
        id: 'wins',
        name: 'W/L',
        width: '20%',
        valueAccessor: (row) => `${row.wins} - ${row.losses}`
    }
];

function calculateWL(games) {
    const teams = {};
    const teamIds = new Set();
    let finishedGame = false;

    games.forEach((game) => {
        const team1 = game.roster1.team;
        const team2 = game.roster2.team;
        if (!teamIds.has(game.roster1.team.id)) {
            teamIds.add(team1.id);
            teams[team1.id] = {
                name: `${team1.name} (${game.roster1.seed})`,
                seed: game.roster1.seed,
                slug: team1.slug,
                rosterId: game.roster1.id,
                wins: 0,
                losses: 0
            };
        }
        if (!teamIds.has(game.roster2.team.id)) {
            teamIds.add(game.roster2.team.id);
            teams[team2.id] = {
                name: `${team2.name} (${game.roster2.seed})`,
                seed: game.roster2.seed,
                slug: team2.slug,
                rosterId: game.roster2.id,
                wins: 0,
                losses: 0
            };
        }

        if (game.status.toLowerCase() === 'final') {
            finishedGame = true;
            const winner = game.score1 > game.score2 ? team1.id : team2.id;
            const loser = game.score1 > game.score2 ? team2.id : team1.id;

            teams[winner].wins++;
            teams[loser].losses++;
        }
    });
    const teamsList = finishedGame
        ? Object.values(teams).sort((a, b) => b.wins - a.wins)
        : Object.values(teams).sort((a, b) => -b.seed + a.seed);

    return teamsList;
}

export default function Pool(props) {
    const [pool, setPool] = useState({});
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        setPool(props.pool);
        setTeams(calculateWL(props.pool.games));
    }, [props.pool]);
    return (
        <Box
            sx={{
                minWidth: '270px',
                border: 1,
                borderColor: 'divider',
                borderRadius: '8px',
                m: 1
            }}>
            <Typography variant="h6" sx={{ my: 1, mx: 2 }}>
                {pool.name}
            </Typography>
            <DataTable columns={poolColumns} rows={teams} useContainer={false}></DataTable>
        </Box>
    );
}
