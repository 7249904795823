import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import DataTable from 'lib/DataTable';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    getOngoingTournaments,
    getRecentlyEndedTournaments,
    getUpcomingTournaments
} from 'services/TeamService';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { DIVISIONS_TRANSFORM, GENDER_TRANSFORM, GENDER_TRANSFORM_SHORT } from 'utils/constants';
import HomepageTournaments from './HomepageTournaments';
import Articles from './Articles';

const listItems = [
    `Jump between related records by clicking on player, team, and tournament names.`,
    'To get started pick a tournament below, or search by typing a team, player, or tournament name into the search box.',
    'Have you played in a sanctioned college or club ultimate tournament since 2014? Search for your own name!'
];

export default function Hello() {
    let session = useSessionContext();
    const navigate = useNavigate();

    if (session.loading) {
        return null;
    }

    return (
        <Box>
            <h1>Into the Ultiverse!</h1>
            {/* <Grid container spacing={2}>
                {buttonGridItems.map((item, index) => (
                    <Grid item key={index}>
                        <Button
                            onClick={() => navigate(item.link)}
                            variant="outlined"
                            sx={{ px: '40px', py: '30px', maxWidth: '300px', height: '200px' }}>
                            {item.text}
                        </Button>
                    </Grid>
                ))}
            </Grid> */}

            <List>
                {listItems.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={item}></ListItemText>
                    </ListItem>
                ))}
            </List>
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                        Tournaments
                    </Typography>
                    <HomepageTournaments />
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                        Articles
                    </Typography>
                    <Articles />
                </Grid> */}
            </Grid>
        </Box>
    );
}
