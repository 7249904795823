import * as React from 'react';
import { useParams, useNavigate, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    getMostRecentRoster,
    getRostersByTeamId,
    getTeam,
    getTeamBySlug,
    getTeamRecentResults
} from '../../services/TeamService';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import {
    Autocomplete,
    Card,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import Roster from './Roster';
import Loading from '../../lib/Loading';
import ObjectHeader from '../../lib/ObjectHeader';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DataTable from 'lib/DataTable';
import { formatDate } from 'utils/formatDatetime';
import { useTheme } from '@emotion/react';
import { getScoreBackgroundColor } from '../tournament/Pools';
import TabPanel from 'lib/TabPanel';
import { getVideosForTeam } from 'services/VideoService';
import RecentResults from './RecentResults';
import TeamVideos from './TeamVideos';

const tabs = [
    { label: 'Overview', to: '' },
    { label: 'Rosters', to: 'roster' },
    { label: 'Recent Results', to: 'results' },
    { label: 'Film', to: 'film' }
];

export default function Team(props) {
    let params = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [team, setTeam] = useState({});
    const [recentRoster, setRecentRoster] = useState({});
    // const [selectedRoster, setSelectedRoster] = useState(null);

    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(1);

    useEffect(() => {
        setLoading(true);
        setRecentRoster({});
        setTeam({});
        getTeamBySlug({ slug: params.slug }, (team) => {
            setTeam(team);
            props.setName(team.name);

            getMostRecentRoster(params.slug, (roster) => {
                setLoading(false);
                if (!roster) return;
                setRecentRoster(roster);
            });
        });
    }, [params.slug]);

    useEffect(() => {
        const index = tabs.findIndex((tab) => tab.to === location.pathname.split('/')[3]);
        setSelectedTab(index === -1 ? 0 : index);
    }, [location]);

    const handleChange = (event, newValue) => {
        navigate(event.target.getAttribute('to'));
        setSelectedTab(newValue);
    };

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <ObjectHeader
                        name={team.name}
                        createdAt={team.createdAt}
                        updatedAt={team.updatedAt}
                        type="team"
                        division={team.division}
                        gender={team.gender}
                        team={team}
                        noDivider={true}
                    />

                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        sx={{ mb: 2, borderBottom: '1px solid grey' }}>
                        {tabs.map((tab) => (
                            <Tab key={tab.to} label={tab.label} to={tab.to} />
                        ))}
                    </Tabs>

                    {selectedTab === 0 ? (
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                                <Card sx={{ py: 2 }}>
                                    <Box sx={{ px: 2 }}>
                                        <Typography variant="h6">Current Roster</Typography>
                                        {!!recentRoster.tournament && (
                                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                                {recentRoster.tournament.name}
                                            </Typography>
                                        )}
                                    </Box>
                                    {!!recentRoster.id && <Roster rosterId={recentRoster.id} />}
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                                <Card sx={{ py: 2, mb: 2 }}>
                                    <Typography variant="h6" sx={{ mx: 2 }}>
                                        Recent Results
                                    </Typography>
                                    <RecentResults slug={params.slug} />
                                    <Divider />
                                    <Button
                                        sx={{ float: 'right', mt: 2, mr: 2 }}
                                        variant="outlined"
                                        onClick={() => navigate(`/team/${params.slug}/results`)}>
                                        View More
                                    </Button>
                                </Card>
                                <Card sx={{ py: 2 }}>
                                    <Typography variant="h6" sx={{ mx: 2 }}>
                                        Game Film
                                    </Typography>
                                    <TeamVideos slug={params.slug} videoMenu={false} />
                                    <Divider />
                                    <Button
                                        sx={{ float: 'right', mt: 2, mr: 2 }}
                                        variant="outlined"
                                        onClick={() => navigate(`/team/${params.slug}/film`)}>
                                        View More
                                    </Button>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : null}

                    <Outlet></Outlet>
                </div>
            )}
        </div>
    );
}
