export const GENDER_TRANSFORM = {
    0: 'Open',
    1: 'Mixed',
    2: 'Women',
    3: 'Boys',
    4: 'Girls'
};

export const DIVISIONS_TRANSFORM = {
    0: 'College',
    1: 'Club',
    2: 'Youth Club U-20',
    3: 'Youth Club U-17'
};

export const STAGES_TRANSFORM = {
    0: 'Pools',
    1: 'Bracket',
    2: 'Cluster'
};
