import DataTable from 'lib/DataTable';

export const playerColumns = [
    {
        id: 'team',
        name: 'Team',
        valueAccessor: (row) => row.team.name,
        navigateOnClick: (row) => `/team/${row.team.slug}/roster/${row.id}`
    },
    {
        id: 'tournament',
        name: 'Tournament',
        valueAccessor: (row) => row.tournament.name,
        navigateOnClick: (row) => `/tournament/${row.tournament.slug}`
    },
    {
        id: 'date',
        name: 'Date',
        valueAccessor: (row) => row.tournament.date.toLocaleDateString(),
        sortAccessor: (row) => row.tournament.date
    }
];

export default function PersonRosters(props) {
    return <DataTable rows={props.rosters} columns={playerColumns}></DataTable>;
}
