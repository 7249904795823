import { Stack, Link } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import { signOut, useClaimValue, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';

const footerItems = [
    { text: 'Home', path: '/' },
    { text: 'About', path: '/about' },
    { text: 'Admin', path: '/admin/stats', requiresAdmin: true },
    { text: 'Profile', path: '/profile', requiresLogin: true }
    // { text: 'Contact', path: '/contact' }
];

export default function Footer() {
    const navigate = useNavigate();
    const session = useSessionContext();
    let claimValue = useClaimValue(UserRoleClaim);
    const [showLogout, setShowLogout] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setShowLogout(session.doesSessionExist);
        setIsAdmin(false);
    }, [session.doesSessionExist]);

    useEffect(() => {
        if (claimValue?.loading || !claimValue?.doesSessionExist) {
            return;
        }
        setIsAdmin(claimValue.value.includes('admin'));
    }, [claimValue]);

    async function onLogin() {
        SuperTokens.redirectToAuth();
    }
    async function onLogout() {
        await signOut();
        navigate('/');
    }
    return (
        <footer>
            <Stack direction="row" spacing={2}>
                {footerItems.map((item) => {
                    if (item.requiresAdmin && !isAdmin) {
                        return;
                    }
                    if (item.requiresLogin && !showLogout) {
                        return;
                    }
                    return (
                        <Link
                            component="button"
                            key={item.text}
                            onClick={() => navigate(item.path)}>
                            {item.text}
                        </Link>
                    );
                })}
                {showLogout ? (
                    <Link component="button" onClick={() => onLogout()}>
                        Logout
                    </Link>
                ) : (
                    <Link component="button" onClick={() => onLogin()}>
                        Login
                    </Link>
                )}
            </Stack>
        </footer>
    );
}
