import { Box, Grid, Link } from '@mui/material';
import { APP_CONFIG } from 'config/config';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { timeAgo } from 'utils/formatDatetime';

export function GetRecentlyUpdatedTournaments() {
    return fetch(`${APP_CONFIG.apiUrl}/v2/teams/recently-updated/tournaments/`).then((response) =>
        response.json()
    );
}

export function GetRecentlyUpdatedGames() {
    return fetch(`${APP_CONFIG.apiUrl}/v2/teams/recently-updated/games/`).then((response) =>
        response.json()
    );
}

const tournamentColumns = [
    {
        id: 'updatedAt',
        name: 'Updated At',
        valueAccessor: (row) => timeAgo(row.updatedAt)
    },
    {
        id: 'name',
        name: 'Name',
        navigateOnClick: (row) => `/tournament/${row.slug}`
    }
];

const gameColumns = [
    {
        id: 'updatedAt',
        name: 'Updated At',
        valueAccessor: (row) => timeAgo(row.updatedAt)
    },
    {
        id: 'name',
        name: 'Game',
        valueAccessor: (row) => `${row.roster1.team.name} vs ${row.roster2.team.name}`,
        navigateOnClick: (row) => `/game/${row.id}`
    }
];

export default function RecentlyScraped() {
    const [recentlyUpdatedTournaments, setRecentlyUpdatedTournaments] = useState([]);
    const [recentlyUpdatedGames, setRecentlyUpdatedGames] = useState([]);

    useEffect(() => {
        GetRecentlyUpdatedTournaments().then((data) => {
            setRecentlyUpdatedTournaments(data);
        });

        GetRecentlyUpdatedGames().then((data) => {
            setRecentlyUpdatedGames(data);
        });
    }, []);

    return (
        <Box>
            <Link href="https://github.com/ultidb/source-data/commits/live" target="_blank">
                github.com/ultidb/source-data/commits/live
            </Link>
            <h1>Recently scraped</h1>

            <h2>Tournaments</h2>
            <DataTable rows={recentlyUpdatedTournaments} columns={tournamentColumns} />

            <h2>Games</h2>
            <DataTable rows={recentlyUpdatedGames} columns={gameColumns} />
        </Box>
    );
}
