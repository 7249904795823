import { Videocam } from '@mui/icons-material';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function VideoMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <Box sx={props.sx}>
            <IconButton onClick={handleClick} sx={{ padding: '4px' }}>
                <Videocam />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {props.videos.map((video) => (
                    <MenuItem
                        onClick={() => {
                            handleClose(video);
                            navigate('/video/' + video.id);
                        }}
                        key={video.id}>
                        Watch on {video.source.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
