import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getGameById,
    getRosterPlayers,
    getTeamBySlug,
    getTournamentById
} from 'services/TeamService';
import { getScoreBackgroundColor } from './tournament/Pools';
import Loading from 'lib/Loading';

export default function Game(props) {
    let params = useParams();
    let navigate = useNavigate();
    let theme = useTheme();
    const [game, setGame] = useState({});
    const [loading, setLoading] = useState(true);
    const [team1, setTeam1] = useState({});
    const [team2, setTeam2] = useState({});
    const [tournament, setTournament] = useState({});
    const [players1, setPlayers1] = useState([]);
    const [players2, setPlayers2] = useState([]);

    useEffect(() => {
        setLoading(true);
        getGameById({ id: params.id }, (resp) => {
            setGame(resp);
            getTeamBySlug({ slug: resp.roster1.teamSlug }, (resp) => {
                setTeam1(resp);
            });
            getTeamBySlug({ slug: resp.roster2.teamSlug }, (resp) => {
                setTeam2(resp);
            });
            getTournamentById({ id: resp.tournamentId }, (resp) => {
                setTournament(resp);
            });

            getRosterPlayers({ id: resp.roster1Id }, (players) => {
                if (!players?.length) {
                    return;
                }
                const sorted = players.sort((a, b) => {
                    return a.number === 'n/a' ? -1 : parseInt(a.number) - parseInt(b.number);
                });
                setPlayers1(sorted);
            });

            getRosterPlayers({ id: resp.roster2Id }, (players) => {
                if (!players?.length) {
                    return;
                }
                const sorted = players.sort((a, b) => {
                    return a.number === 'n/a' ? -1 : parseInt(a.number) - parseInt(b.number);
                });
                setPlayers2(sorted);
            });
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (team1.name && team2.name) {
            props.setName(`${team1.name} vs ${team2.name}`);
        }
    }, [team1, team2]);

    return (
        <Container>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Typography variant="h4">
                        {team1.name} vs. {team2.name}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                color: theme.palette.primary.dark
                            }
                        }}
                        onClick={() => navigate('/tournament/' + tournament.slug)}>
                        {tournament.name}
                    </Typography>
                    <Typography variant="caption">{game.datetimeDisplay}</Typography>

                    <Grid container spacing={2} p={2}>
                        <Grid item key={'recent'} xs={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    px: '20px',
                                    py: '64px',
                                    m: '16px',
                                    borderRadius: '8px',
                                    backgroundColor: (row) =>
                                        getScoreBackgroundColor(
                                            game.score1,
                                            game.score2,
                                            'final',
                                            theme
                                        )
                                }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: theme.palette.primary.dark
                                            }
                                        }}
                                        onClick={() => navigate('/team/' + team1.slug)}>
                                        {team1.name}
                                    </Typography>
                                    <Typography variant="h6">{game.score1}</Typography>
                                </Box>
                            </Box>
                            <DataTable
                                columns={[
                                    {
                                        id: 'number',
                                        name: 'Number',
                                        width: '5%',
                                        valueAccessor: (row) =>
                                            row.role === 'coach' ? 'Coach' : row.number,
                                        sortAccessor: (row) => parseInt(row.number)
                                    },
                                    {
                                        id: 'name',
                                        name: 'Name',
                                        valueAccessor: (row) => row.name,
                                        navigateOnClick: (row) => `/person/${row.slug}`
                                    }
                                ]}
                                rows={players1}></DataTable>
                        </Grid>
                        <Grid item key={'upcoming'} xs={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    px: '20px',
                                    py: '64px',
                                    m: '16px',
                                    borderRadius: '8px',
                                    backgroundColor: (row) =>
                                        getScoreBackgroundColor(
                                            game.score2,
                                            game.score1,
                                            'final',
                                            theme
                                        )
                                }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: theme.palette.primary.dark
                                            }
                                        }}
                                        onClick={() => navigate('/team/' + team2.slug)}>
                                        {team2.name}
                                    </Typography>
                                    <Typography variant="h6">{game.score2}</Typography>
                                </Box>
                            </Box>
                            <DataTable
                                columns={[
                                    {
                                        id: 'number',
                                        name: 'Number',
                                        width: '5%',
                                        valueAccessor: (row) =>
                                            row.role === 'coach' ? 'Coach' : row.number,
                                        sortAccessor: (row) => parseInt(row.number)
                                    },
                                    {
                                        id: 'name',
                                        name: 'Name',
                                        valueAccessor: (row) => row.name,
                                        navigateOnClick: (row) => `/person/${row.slug}`
                                    }
                                ]}
                                rows={players2}></DataTable>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
}
